import React , { useState, useEffect, Component } from 'react';
import {Line} from 'react-chartjs-2';
import axios from "axios";
import { emptyStatement } from '@babel/types';

const State = () => {
    const[chartData,setChartData]= useState({});
    const[DateStore,setDate]= useState({});
    const[TotalStore,setTotal]= useState({});
   
    function handleChange(e){

            if(e.target.value=='2'){
                let url = 'https://competes.co/pyapi/data/get?source=groupon.com';
                chart(url)
            }
            if(e.target.value=='1'){
                let url = 'https://competes.co/pyapi/data/get?source=coupons.com';
                chart(url)
            }
            if(e.target.value=='3'){
                let url = 'https://competes.co/pyapi/data/get?source=dealcatcher.com';
                chart(url)
            }
            if(e.target.value=='4'){
                let url = 'https://competes.co/pyapi/data/get?source=slickdeals.net';
                chart(url)
            }
            if(e.target.value=='5'){
                let url = 'https://competes.co/pyapi/data/get?source=savings.com';
                chart(url)
            }
      }
      function random_item(items)
            {
            
            return items[Math.floor(Math.random()*items.length)];
                
            }

            var items = ['ff6384', '36a2eb', '000', '4bc0c0', '77b5fe','87ceeb','aaa'];
            console.log(random_item(items));
      function getDataset(name,rank, data) { 
          let color = '#000';
          if(name!='' && data!=''){
              if(name=='Burlington Coat Factory'){ color = '#ff6384' }
              if(name=='LensCrafters'){ color = '#36a2eb' }
              if(name=='Athleta'){ color = '#63ff90' }
              if(name=='New Balance'){ color = '#de63ff' }
              if(name=='Vans'){ color = '#ffde63' }
              if(name=='Tillys'){ color = '#aaa' }
              if(name=='Paige Denim'){ color = '#ff9063' }
        return { 
            label: name+'(Rank '+ rank +' )',
            fill: false,
            lineTension: 0.5,
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: color,
            borderWidth: 2,
            data: data 
        }; 
    }
        }
    const chart = (url) =>{
        let DatSto=[];
        let TotSto=[];
        let TotStoA=[];
        let TotStoBU=[];
        let one=0;
        let AllStoreData=[];
       // let url = 'https://competes.co/pyapi/data/get?source=coupons.com';
       axios.get('https://competes.co/pyapi/data/alexa?store_name=all')
        .then(resfirst=>{
            
        axios.get(url)
        .then(res=>{
            for(const dataObj of res.data.data){
                if(dataObj.store_name=='New Balance'){
                    DatSto.push(dataObj.inserted)
                }
            }
            
            let returnval=[];
            for(const dataObj1 of resfirst.data.data){
                let nameavaibel =1;
                for(const dd of DatSto){
                for(const dataObj of res.data.data){
                    
                    if(dataObj.store_name==dataObj1.name){  
                
                   // let one=0;
                   nameavaibel =2;
                    
                        if(dd==dataObj.inserted){
                        //TotSto.push(parseInt(dataObj.total))
                         one = dataObj.total;
                        }
                         
                    }
                   
                    }
                    if(nameavaibel==2){
                    TotSto.push(parseInt(one)) }             
        }
        one='';
                 if(TotSto.length>0){
                 AllStoreData.push(getDataset(dataObj1.name,dataObj1.alexa_rank, TotSto))
                 
                 }
                 TotSto=[]; 
              
            }
            
            
            const listItems = AllStoreData.map((i,number) =>  number);
            //console.log(listItems)
            //const result = Object.values(AllStoreData);
            //console.log(AllStoreData);
            if(AllStoreData.length==8){
            setChartData({
                labels: DatSto,
                datasets: [
                    AllStoreData[0],AllStoreData[1],AllStoreData[2],AllStoreData[3],
                    AllStoreData[4],AllStoreData[5],AllStoreData[6],AllStoreData[7],
                  ]
                
            })
        }
        if(AllStoreData.length==6){
            setChartData({
                labels: DatSto,
                datasets: [
                    AllStoreData[0],AllStoreData[1],AllStoreData[2],AllStoreData[3],
                    AllStoreData[4],AllStoreData[5]
                  ]
                
            })
        }
        if(AllStoreData.length==7){
            setChartData({
                labels: DatSto,
                datasets: [
                    AllStoreData[0],AllStoreData[1],AllStoreData[2],AllStoreData[3],
                    AllStoreData[4],AllStoreData[5],AllStoreData[6],
                  ]
                
            })
        }if(AllStoreData.length==9){
            setChartData({
                labels: DatSto,
                datasets: [
                    AllStoreData[0],AllStoreData[1],AllStoreData[2],AllStoreData[3],
                    AllStoreData[4],AllStoreData[5],AllStoreData[6],AllStoreData[7],AllStoreData[8],
                  ]
                
            })
        }if(AllStoreData.length==10){
            setChartData({
                labels: DatSto,
                datasets: [
                    AllStoreData[0],AllStoreData[1],AllStoreData[2],AllStoreData[3],
                    AllStoreData[4],AllStoreData[5],AllStoreData[6],AllStoreData[7],AllStoreData[8],
                    AllStoreData[9],
                  ]
                
            })
        }
        if(AllStoreData.length==11){
            setChartData({
                labels: DatSto,
                datasets: [
                    AllStoreData[0],AllStoreData[1],AllStoreData[2],AllStoreData[3],
                    AllStoreData[4],AllStoreData[5],AllStoreData[6],AllStoreData[7],AllStoreData[8],
                    AllStoreData[9],AllStoreData[10],
                  ]
                
            })
        }if(AllStoreData.length==12){
            setChartData({
                labels: DatSto,
                datasets: [
                    AllStoreData[0],AllStoreData[1],AllStoreData[2],AllStoreData[3],
                    AllStoreData[4],AllStoreData[5],AllStoreData[6],AllStoreData[7],AllStoreData[8],
                    AllStoreData[9],AllStoreData[10],AllStoreData[11],
                  ]
                
            })
        }
        }).catch(err=>{
                console.log(err);  
            }
        )

        console.log(TotSto);
    }).catch(errfirst=>{
        console.log(errfirst);  
    }
)

       
    }

    useEffect(() =>{
    
       let url = 'https://competes.co/pyapi/data/store?store_name=all';
        chart(url)
    }, [])
    
  return(
      <div className="App">
<h1 style={{textAlign:"center"}}>All data Chart<br/>
</h1>

 <div>
     <Line data={chartData} />
     </div>    
      
      </div>

  )
}

export default State;

