import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';

class LoginPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render(){

    return(
         <div>
             <div class="main-outer-area loginpage">


<div class="main-content-area ">
    
    <div class="main-banner inner signup">

        <div class="container">

            <header class="main-header d-flex justify-content-center">
               
            </header>

        </div>

    </div>

    

    <div class="login-container">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="login-wrap text-center">
                    <div class="logoinner"> <NavLink to="/">Competes</NavLink></div>
                       
                        <div class="login-btns">
                        <h4 class="signinh4">Sign in</h4>
                            <a href="javascript:void(0)" class="btn btn-gray">
                                
                                <input type="text" class="form-control" placeholder="Email" />
                            </a>
                            <a href="javascript:void(0)" class="btn btn-gray">
                               
                               
                                <input type="text" class="form-control" placeholder="Password" />
                            </a>
                            
                         
                            
                        </div>
                        <div class="submit-btn">
                                <button id="go-to-nextStep"  onClick={this.toggleBox} class="btn btn-lg btn-flat btn-block btn-primary">Next</button>
                            </div>

                        <div class="signup-here">
                            New to Competes? <NavLink to="/signup">Signup here</NavLink>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div> </div>
<footer class="footer footer-site wrap-site-footer bg-darker">
    <div class="wrap-site-footer-inside">
        <div class="footer-top">
            <div class="container">
                <div class="row flex-row row-footer-nav justify-content-start justify-content-md-between">
                    <div class="col col-12 col-lg-4 col-xl-3 my-1 order-last order-lg-first">
                        <div class="organization py-2 py-md-1 py-lg-0" >
                            <meta itemprop="name" content="Competes" />
                            <figure class="logo">
                               
                                <a aria-current="page" class="active" href="/">Competes</a>
                               
                            </figure>
                            <div>
                                
                                <div id="choice-footer-msg" class="choice-footer-msg ccpa-msg-added">
                                    We use cookies and other data collection technologies to provide the best experience for our customers. You may request that your data not be shared with third parties here:
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col col-12 col-lg-8 my-1 order-first order-lg-last">
                        <div class="row">
                           

                            <div class="col col-6 col-sm-3 mb-2">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_solutions_navigation">
                                    <div class="nav-header">
                                        Solutions
                                    </div>
                                    <div class="menu-footer-solutions-container">
                                        <ul id="menu-footer-solutions" class="nav d-flex flex-column" >
                                            <li id="menu-item-49409" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49409">
                                            <a href="#">
                                                    Publishers
                                                </a>
                                            </li>
                                            <li id="menu-item-49410" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49410">
                                            <a href="#">
                                                    Advertisers
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-3 mb-2">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_resources_navigation">
                                    <div class="nav-header">
                                        Resources
                                    </div>
                                    <div class="menu-footer-resources-navigation-container">
                                        <ul id="menu-footer-resources-navigation" class="nav d-flex flex-column" >
                                            <li id="menu-item-337" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-337">
                                            <a href="#">Blog</a>
                                            </li>
                                            <li id="menu-item-336" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-336">
                                            <a href="#">Webinars</a>
                                            </li>
                                            <li id="menu-item-47821" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-47821">
                                            <a href="#">Case Studies</a>
                                            </li>
                                            <li id="menu-item-63305" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-63305">
                                            <a href="#">Data Insights</a>
                                            </li>
                                            <li id="menu-item-47822" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-47822">
                                            <a href="#">Help Center</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-3 col-lg-2">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_company_navigation">
                                    <div class="nav-header">
                                        Company
                                    </div>
                                    <div class="menu-footer-company-navigation-container">
                                        <ul id="menu-footer-company-navigation" class="nav d-flex flex-column" >
                                            <li id="menu-item-47827" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-47825 current_page_item menu-item-47827">
                                            <a href="#">About</a>
                                            </li>
                                            <li id="menu-item-60548" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-60548">
                                            <a href="#">Press</a>
                                            </li>
                                            <li id="menu-item-47828" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-47828">
                                            <a href="#">Careers</a>
                                            </li>
                                            <li id="menu-item-338" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-338">
                                            <a href="#">Contact</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-bottom">
            <div class="container">
                <div class="row flex-row">
                    <div class="col col-12 col-lg-4 text-center text-md-left d-flex align-items-center justify-content-center justify-content-md-start">
                        <div class="nav nav-legal py-1 py-lg-0">
                            <div class="footer-nav-wrapper" id="footer_legal_navigation">
                                <div class="menu-footer-legal-navigation-container">
                                    <ul id="menu-footer-legal-navigation" class="nav d-flex flex-row" >
                                       
                                        <li id="menu-item-47844" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-47844">
                                        <a href="#">Terms &amp; Conditions</a>
                                        </li>
                                        <li id="menu-item-47845" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-47845">
                                        <a href="#">Privacy</a>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col col-12 col-md-6 col-lg-4 text-left text-md-right align-items-center justify-content-center d-flex justify-content-md-start justify-content-lg-center">
                        <div class="legal text-center text-md-left text-lg-center py-1 py-lg-0">
                            © 2021 Competes. All Rights Reserved.
                        </div>
                    </div>

                    <div class="col col-12 col-md-6 col-lg-4 text-center text-md-right">
                        <div class="nav nav-social text-md-right py-1 py-lg-0">
                            <a class="social-icon-link" href="#" target="_blank" title="Twitter">
                                <svg class="svg-inline--fa fa-twitter fa-w-16" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                    <path
                                        fill="currentColor"
                                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                    ></path>
                                </svg>
                               
                                <span class="sr-only">Twitter (External link)</span>
                            </a>
                            <a class="social-icon-link" href="#" target="_blank" title="LinkedIn">
                                <svg
                                    class="svg-inline--fa fa-linkedin fa-w-14"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="linkedin"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    data-fa-i2svg=""
                                >
                                    <path
                                        fill="currentColor"
                                        d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                                    ></path>
                                </svg>
                               
                                <span class="sr-only">LinkedIn (External link)</span>
                            </a>
                            <a class="social-icon-link" href="#" target="_blank" title="FaceBook">
                                <svg
                                    class="svg-inline--fa fa-facebook fa-w-16"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="facebook"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    data-fa-i2svg=""
                                >
                                    <path
                                        fill="currentColor"
                                        d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                                    ></path>
                                </svg>
                              
                                <span class="sr-only">FaceBook (External link)</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
 </div>
    );
}

}

export default LoginPage;
