import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';



class Privacy extends React.Component {

    componentWillMount () {
        
        const script = document.createElement("script");
     
        script.src = "/assets/js/menutoggle.js";
       
        script.async = true;
     
        document.body.appendChild(script);
       
      }
      
      render(){
 
        return(
            <div class="privacypageupperdiv  page-create-account verify">
            <div class="main-banner companybg newonetop pri thissignup">
        
                <div class="container">
                
                <header class="main-header d-flex justify-content-between">
               <div class="site-logo">
                  
                  <NavLink to="/">competes</NavLink>
               </div>
               
            </header>
                
            </div> </div> 
    
            <section class="sectionBuilder hero --feature-callout -first">
    <div class="container -full">
        <div class="row -center hero-row">
            <div class="signupForm-homepage-center col-xsmall-12 col-small-8 col-small-offset-2 col-medium-10 col-medium-offset-1 col-large-8 col-large-offset-2 col-xlarge-6 col-xlarge-offset-3">
                <h4 class="heading -spacing-2 -h3">Check your  email for a code</h4>
                <p class="subheading -spacing-1 hero-legal-copy exveri">
                    we have sent a 6-character code. The code expires shortly,so please enter it soon.
                </p>
                <form action="verify" method="get" novalidate="" class="signupForm hidden-logged-in signupForm-homepage-center" __bizdiag="1239199729" __biza="WJ__">
                    <div class="signupForm-container">
                        <div class="signupForm-row">
                        <div class="form-group thisform">
                                
                                <input minlength="1" maxlength="1" type="text" name="1v" id="dk" required  class="form-control thi"/>
                                <input minlength="1" maxlength="1" type="text" name="1v" id="dk" required  class="form-control thi"/>
                                <input minlength="1" maxlength="1" type="text" name="1v" id="dk" required  class="form-control thi"/>
                                
                                <input minlength="1" maxlength="1" type="text" name="1v" id="dk" required  class="form-control thi"/>
                                <input minlength="1" maxlength="1" type="text" name="1v" id="dk" required  class="form-control thi"/>
                                <input minlength="1" maxlength="1" type="text" name="1v" id="dk" required  class="form-control thi"/>

                            </div>
                            <p class="upp">Can't find your code? check your spam email folder!</p>

                            
                        </div>
                    </div>
                </form>
                
            </div>
        </div>
    </div>
</section>

       
       </div>
        );
    }
}

export default Privacy;