import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';



class Careers extends React.Component {

    componentWillMount () {
        
        const script = document.createElement("script");
     
        script.src = "/assets/js/menutoggle.js";
       
        script.async = true;
     
        document.body.appendChild(script);
       
      }
      
      render(){
 
        return(
            <div class="careerspageupperdiv">
            <div class="main-banner companybg newonetop">
        <div class="container bo">
                <img src="assets/images/top-banner-below-3-3px.svg" class="middlediv container" />
                </div>
                <div class="container">
                <img src="assets/images/top-banner-below-3-3px.svg" class="middlediv container" />
                <header class="main-header d-flex justify-content-between">
               <div class="site-logo">
                  
                  <NavLink to="/">competes</NavLink>
               </div>
               <div class="menu-toggle-icon">
                  <span class="hamburger-inner"></span>
               </div>
               <div class="site-login">
                  <div class="menu-toggle-bg"></div>
                  <div class="site-login-inner">
                     <div class="site-logo menu-with-logo">
                        <a href="javascript:void(0)">competes</a>
                     </div>
                      
                     <a href="javascript:void(0)" className="mr-4 top-link mobi" id="get-start">Get Started</a>
                      <NavLink to="/signals" className="mr-4 top-link mobi" >Signals</NavLink>
                     
                     <NavLink to="/solutions" className="mr-4 top-link">Solutions</NavLink>
                     <NavLink to="/signals" className="mr-4 top-link desk">Signals</NavLink>
                     <NavLink to="/login" className="mr-4 top-link mobi">Login</NavLink>
                     <NavLink to="/company" className="mr-4 top-link">Company</NavLink> 
                     <NavLink to="/login" className="mr-4 top-link desk">Login</NavLink>
                     <NavLink to="/get-started/signup" className="btn btn-lg btn-flat btn-outline ml-3 desk get33">Get Started</NavLink>
                  </div>
               </div>
            </header>
                
            <div class="row justify-content-center">
               <div class="col-md-9">
               <div class="banner-content text-center comp">
                     <h1 class="banner-title">Build Your Career at competes</h1>
                     <div class="banner-description">
                        <p><span class="freet" >competes provides real-time insights about your audience</span></p>
                     </div>
                    
                     </div>
            
               
               </div> </div>  </div> </div> 
    
                    <div class="call-to-action container combg">
                <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-7">
                        
                    </div>
                    <div class="col-md-5">
                        
                    </div>
                </div>
                <div id="imgf">

                <img src="assets/images/open-source.jpg" />
                <img src="assets/images/open-source.jpg" />
                <img src="assets/images/open-source.jpg" />

                </div>
                </div>
                </div>


                    <div class="c-featured-press g-grid-container">
    <div class="featured-item">
        <a href="#">
            <div class="image media-coverage">
                <picture data-testid="image-root">
                    <source
                        type="image/webp"
                        srcset="
                            assets/images/inc-magazinelogo.png?fit=max&amp;fm=webp&amp;q=80&amp;w=250   250w,
                            assets/images/inc-magazinelogo.png?fit=max&amp;fm=webp&amp;q=80&amp;w=500   500w,
                            assets/images/inc-magazinelogo.png?fit=max&amp;fm=webp&amp;q=80&amp;w=750   750w,
                            assets/images/inc-magazinelogo.png?fit=max&amp;fm=webp&amp;q=80&amp;w=1000 1000w,
                            assets/images/inc-magazinelogo.png?fit=max&amp;fm=webp&amp;q=80&amp;w=1500 1500w,
                            assets/images/inc-magazinelogo.png?fit=max&amp;fm=webp&amp;q=80&amp;w=2000 2000w,
                            assets/images/inc-magazinelogo.png?fit=max&amp;fm=webp&amp;q=80&amp;w=2500 2500w
                        "
                        sizes="100vw"
                        data-testid="webp-source"
                    />
                    <img
                        src="assets/images/inc-magazinelogo.png?fit=max&amp;fm=png&amp;q=80"
                        srcset="
                            assets/images/inc-magazinelogo.png?fit=max&amp;fm=png&amp;q=80&amp;w=250   250w,
                            assets/images/inc-magazinelogo.png?fit=max&amp;fm=png&amp;q=80&amp;w=500   500w,
                            assets/images/inc-magazinelogo.png?fit=max&amp;fm=png&amp;q=80&amp;w=750   750w,
                            assets/images/inc-magazinelogo.png?fit=max&amp;fm=png&amp;q=80&amp;w=1000 1000w,
                            assets/images/inc-magazinelogo.png?fit=max&amp;fm=png&amp;q=80&amp;w=1500 1500w,
                            assets/images/inc-magazinelogo.png?fit=max&amp;fm=png&amp;q=80&amp;w=2000 2000w,
                            assets/images/inc-magazinelogo.png?fit=max&amp;fm=png&amp;q=80&amp;w=2500 2500w
                        "
                        sizes="100vw"
                        data-testid="img"
                    />
                </picture>
            </div>
            <p class="g-type-label-strong">Media Coverage</p>
            <h6 class="g-type-display-6">Why competes Favored a Remote-First Workforce Long Before the Pandemic</h6>
            <p class="g-type-body-small">31 March 2021 | Inc.</p>
        </a>
    </div>
    <div class="featured-item">
        <a href="#">
            <div class="image media-coverage"><img data-testid="image-root" src="assets/images/forbes.svg" /></div>
            <p class="g-type-label-strong">Media Coverage</p>
            <h6 class="g-type-display-6">competes Vault Cloud Service Goes GA</h6>
            <p class="g-type-body-small">7 April 2021 | Forbes</p>
        </a>
    </div>
    <div class="featured-item">
        <a href="#">
            <div class="image media-coverage">
                <picture data-testid="image-root">
                    <source
                        type="image/webp"
                        srcset="
                            assets/images/tile-final-sflogo.jpeg?fit=max&amp;fm=webp&amp;q=80&amp;w=250   250w,
                            assets/images/tile-final-sflogo.jpeg?fit=max&amp;fm=webp&amp;q=80&amp;w=500   500w,
                            assets/images/tile-final-sflogo.jpeg?fit=max&amp;fm=webp&amp;q=80&amp;w=750   750w,
                            assets/images/tile-final-sflogo.jpeg?fit=max&amp;fm=webp&amp;q=80&amp;w=1000 1000w,
                            assets/images/tile-final-sflogo.jpeg?fit=max&amp;fm=webp&amp;q=80&amp;w=1500 1500w,
                            assets/images/tile-final-sflogo.jpeg?fit=max&amp;fm=webp&amp;q=80&amp;w=2000 2000w,
                            assets/images/tile-final-sflogo.jpeg?fit=max&amp;fm=webp&amp;q=80&amp;w=2500 2500w
                        "
                        sizes="100vw"
                        data-testid="webp-source"
                    />
                    <img
                        src="assets/images/tile-final-sflogo.jpeg?fit=max&amp;fm=jpeg&amp;q=80"
                        srcset="
                            assets/images/tile-final-sflogo.jpeg?fit=max&amp;fm=jpeg&amp;q=80&amp;w=250   250w,
                            assets/images/tile-final-sflogo.jpeg?fit=max&amp;fm=jpeg&amp;q=80&amp;w=500   500w,
                            assets/images/tile-final-sflogo.jpeg?fit=max&amp;fm=jpeg&amp;q=80&amp;w=750   750w,
                            assets/images/tile-final-sflogo.jpeg?fit=max&amp;fm=jpeg&amp;q=80&amp;w=1000 1000w,
                            assets/images/tile-final-sflogo.jpeg?fit=max&amp;fm=jpeg&amp;q=80&amp;w=1500 1500w,
                            assets/images/tile-final-sflogo.jpeg?fit=max&amp;fm=jpeg&amp;q=80&amp;w=2000 2000w,
                            assets/images/tile-final-sflogo.jpeg?fit=max&amp;fm=jpeg&amp;q=80&amp;w=2500 2500w
                        "
                        sizes="100vw"
                        data-testid="img"
                    />
                </picture>
            </div>
            <p class="g-type-label-strong">Media Coverage</p>
            <h6 class="g-type-display-6">How Infrastructure as Code is Accelerating Cloud Adoption, with competes VP of Engineering, Preeti Somal</h6>
            <p class="g-type-body-small">21 April 2021 | IT Visionaries (Mission.Org)</p>
        </a>
    </div>
</div>

                    
<div>
    <section class="c-press-section light press-release-section">
        <div class="g-grid-container">
            <div class="press-release-block">
                <div class="c-press-section-header light upper">
                    
                    <h3 class="g-type-display-3">
                        <span class="mk">Open Positions</span>
                        <br></br>
                        Business Development</h3>
                   
                </div>
                <div class="c-press-releases">
                    <div class="c-press-list-item">
                        <a href="#">
                            <div class="info">
                                <p class="g-type-display-5 title">competes Appoints Sigal Zarmi to Board</p>
                                <p class="g-type-body-small metadata">12 August 2021</p>
                                <a aria-current="page" class="btn btn-outline-primary icon icon-after button-custom active imkla" href="/">Apply
                                <svg focusable="false" class="ibm-duo-u--link-icon--arrow-right" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" slot="icon">
                                    <path d="M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z"></path></svg></a>
                            </div>
                        </a>
                    </div>
                    <div class="c-press-list-item">
                        <a href="#">
                        <div class="info">
                                <p class="g-type-display-5 title">competes Appoints Sigal Zarmi to Board</p>
                                <p class="g-type-body-small metadata">12 August 2021</p>
                                <a aria-current="page" class="btn btn-outline-primary icon icon-after button-custom active imkla" href="/">Apply
                                <svg focusable="false" class="ibm-duo-u--link-icon--arrow-right" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" slot="icon">
                                    <path d="M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z"></path></svg></a>
                            </div>
                        </a>
                    </div>
                    <div class="c-press-list-item">
                        <a href="#">
                        <div class="info">
                                <p class="g-type-display-5 title">competes Appoints Sigal Zarmi to Board</p>
                                <p class="g-type-body-small metadata">12 August 2021</p>
                                <a aria-current="page" class="btn btn-outline-primary icon icon-after button-custom active imkla" href="/">Apply
                                <svg focusable="false" class="ibm-duo-u--link-icon--arrow-right" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" slot="icon">
                                    <path d="M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z"></path></svg></a>
                            </div>
                        </a>
                    </div>
                    <div class="c-press-list-item">
                        <a href="#">
                        <div class="info">
                                <p class="g-type-display-5 title">competes Appoints Sigal Zarmi to Board</p>
                                <p class="g-type-body-small metadata">12 August 2021</p>
                                <a aria-current="page" class="btn btn-outline-primary icon icon-after button-custom active imkla" href="/">Apply
                                <svg focusable="false" class="ibm-duo-u--link-icon--arrow-right" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" slot="icon">
                                    <path d="M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z"></path></svg></a>
                            </div>
                        </a>
                    </div>
                    <div class="c-press-list-item">
                        <a href="#">
                        <div class="info">
                                <p class="g-type-display-5 title">competes Appoints Sigal Zarmi to Board</p>
                                <p class="g-type-body-small metadata">12 August 2021</p>
                                <a aria-current="page" class="btn btn-outline-primary icon icon-after button-custom active imkla" href="/">Apply
                                <svg focusable="false" class="ibm-duo-u--link-icon--arrow-right" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" slot="icon">
                                    <path d="M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z"></path></svg></a>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="c-contact-block">
                <div class="info-container">
                    <h3 class="g-type-display-3">For any press or analyst inquiries, please reach out:</h3>
                    <a class="g-btn size-medium variant-tertiary background-light" data-ga-button="media@hashicorp.com" href="mailto:media@hashicorp.com" rel="noopener" target="_blank">
                        <span class="text g-type-buttons-and-standalone-links">media@hashicorp.com</span>
                        <span class="icon at-right is-animated" data-animation="outbound">
                            <div data-testid="root">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <g data-animation-target="true" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M15 3h6v6m-11 5L21 3"></path></g>
                                </svg>
                            </div>
                        </span>
                    </a>
                </div>
                <div class="social-links">
                    <p class="g-type-label-strong">Follow Us</p>
                    <div class="icons">
                        <a href="#">
                            <div>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M32.694 5H7.13c-1.222 0-2.214.969-2.214 2.162v25.673C4.915 34.03 5.907 35 7.13 35h25.564c1.223 0 2.22-.97 2.22-2.165V7.163c0-1.194-.995-2.163-2.22-2.163zM13.813 16.248v14.317h-4.45V16.248h4.45zm-2.225-7.117a2.581 2.581 0 010 5.16 2.58 2.58 0 110-5.16zm9.284 7.117h-4.27v14.319h4.447v-7.084c0-1.869.356-3.677 2.67-3.677 2.282 0 2.313 2.136 2.313 3.796v6.965h4.446v-7.855c0-3.856-.831-6.82-5.338-6.82-2.164 0-3.616 1.187-4.21 2.312h-.058v-1.956z"
                                        fill="#000"
                                    ></path>
                                </svg>
                            </div>
                        </a>
                        <a href="#">
                            <div>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M19.819 4c-9.106 0-16.5 7.111-16.5 15.89 0 7.023 4.73 12.973 11.284 15.08.82.147 1.124-.341 1.124-.77 0-.38-.01-1.376-.02-2.702-4.589.956-5.561-2.127-5.561-2.127-.75-1.834-1.833-2.321-1.833-2.321-1.5-.986.11-.966.11-.966 1.652.117 2.533 1.639 2.533 1.639 1.469 2.428 3.86 1.726 4.801 1.317.152-1.025.578-1.727 1.044-2.127-3.667-.4-7.516-1.765-7.516-7.852 0-1.736.638-3.15 1.702-4.263-.173-.4-.74-2.019.162-4.204 0 0 1.387-.43 4.537 1.629a16.584 16.584 0 014.133-.537c1.398.01 2.816.186 4.133.537 3.15-2.058 4.537-1.629 4.537-1.629.902 2.185.335 3.804.162 4.204 1.054 1.112 1.692 2.527 1.692 4.263 0 6.106-3.86 7.442-7.536 7.842.588.488 1.124 1.463 1.124 2.946 0 2.127-.02 3.834-.02 4.36 0 .43.294.917 1.134.761C31.6 32.863 36.32 26.913 36.32 19.9c0-8.789-7.394-15.9-16.5-15.9z"
                                        fill="#181616"
                                    ></path>
                                </svg>
                            </div>
                        </a>
                        <a href="#">
                            <div>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M36 20.096c0-8.79-7.124-15.914-15.915-15.914-8.79 0-15.914 7.124-15.914 15.914 0 7.945 5.818 14.529 13.428 15.722V24.697h-4.041v-4.6h4.04V16.59c0-3.988 2.375-6.192 6.012-6.192 1.74 0 3.562.311 3.562.311v3.917h-2.008c-1.977 0-2.592 1.228-2.592 2.486v2.984h4.414l-.706 4.6h-3.708v11.122C30.182 34.625 36 28.041 36 20.096z"
                                        fill="#000"
                                    ></path>
                                </svg>
                            </div>
                        </a>
                        <a href="#">
                            <div>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M33.79 8.716c1.506.395 2.693 1.556 3.095 3.031.732 2.675.732 8.253.732 8.253s0 5.578-.732 8.253c-.402 1.475-1.589 2.636-3.094 3.03C31.062 32 20.117 32 20.117 32S9.17 32 6.443 31.284c-1.505-.395-2.693-1.556-3.094-3.031C2.617 25.578 2.617 20 2.617 20s0-5.578.732-8.253c.401-1.475 1.589-2.636 3.094-3.03C9.171 8 20.117 8 20.117 8s10.945 0 13.674.716zM25.686 20l-9.148 5.065v-10.13L25.685 20z"
                                        fill="#000"
                                    ></path>
                                </svg>
                            </div>
                        </a>
                        <a href="#">
                            <div>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.039 33.054c12.125 0 18.757-10.045 18.757-18.757 0-.285 0-.57-.019-.852a13.414 13.414 0 003.289-3.412c-1.203.533-2.48.882-3.786 1.037a6.615 6.615 0 002.898-3.647 13.212 13.212 0 01-4.186 1.6 6.599 6.599 0 00-11.235 6.013A18.716 18.716 0 016.17 8.148a6.597 6.597 0 002.041 8.8 6.544 6.544 0 01-2.992-.825v.084a6.595 6.595 0 005.29 6.462 6.582 6.582 0 01-2.977.113 6.6 6.6 0 006.159 4.579 13.228 13.228 0 01-9.757 2.732A18.663 18.663 0 0014.04 33.05"
                                        fill="#000"
                                    ></path>
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="c-press-section light">
        <div class="g-grid-container">
            <div class="c-press-section-header light"><h3 class="g-type-display-3">Media Resources</h3></div>
            <div class="c-media-resources">
                <a href="/brand/downloads" data-testid="a-raw" class="resource">
                    <img data-testid="image-root" src="assets/images/zip-icon.svg" />
                    <p class="g-type-body-strong title">competes Brand Resources</p>
                    <p class="g-type-body-small metadata">Aug 27, 2020</p>
                </a>
                <a href="#" data-testid="a-raw" class="resource">
                    <img data-testid="image-root" src="assets/images/zip-icon.svg" />
                    <p class="g-type-body-strong title">competes Executive Headshots</p>
                    <p class="g-type-body-small metadata">ZIP, Aug 27, 2020</p>
                </a>
                <a href="#" data-testid="a-raw" class="resource">
                    <img data-testid="image-root" src="assets/images/zip-icon.svg" />
                    <p class="g-type-body-strong title">competes Fast Facts</p>
                    <p class="g-type-body-small metadata">PDF, Aug 27, 2020</p>
                </a>
            </div>
        </div>
    </section>
</div>

              
    
    <div class="g-call-to-action variant-compact theme-light">
        <div class="g-grid-container">
            <h2 data-testid="heading" class="g-type-display-2">Ready to get started?</h2>
            <div class="content-and-links">
                <p data-testid="content" class="g-type-body-large">Request a demo or talk to our technical sales team to answer your questions.</p>
                <div data-testid="links" class="links">
                    <a class="g-btn size-medium variant-primary background-light" data-ga-button="contact-sales" href="#"><span class="text g-type-buttons-and-standalone-links">Contact Sales</span></a>
                    <a class="g-btn size-medium variant-secondary background-light" data-ga-button="request-demo" href="#"><span class="text g-type-buttons-and-standalone-links">Request Demo</span></a>
                </div>
            </div>
        </div>
    </div>
    
    
       
          <footer class="footer footer-site wrap-site-footer bg-darker">
        <div class="wrap-site-footer-inside">
            <div class="footer-top">
                <div class="container">
                    <div class="row flex-row row-footer-nav justify-content-start justify-content-md-between">
                        <div class="col col-12 col-lg-4 col-xl-3 my-1 order-last order-lg-first">
                            <div class="organization py-2 py-md-1 py-lg-0" >
                                <meta itemprop="name" content="competes" />
                                <figure class="logo">
                                   
                                    <a aria-current="page" class="active" href="/">competes</a>
                                   
                                </figure>
                                <div>
                                    
                                    <div id="choice-footer-msg" class="choice-footer-msg ccpa-msg-added">
                                        We use cookies and other data collection technologies to provide the best experience for our customers. You may request that your data not be shared with third parties here:
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col col-12 col-lg-8 my-1 order-first order-lg-last">
                            <div class="row">
    
                                <div class="col col-6 col-sm-3 mb-2">
                                    <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_solutions_navigation">
                                        <div class="nav-header">
                                            Solutions
                                        </div>
                                        <div class="menu-footer-solutions-container">
                                            <ul id="menu-footer-solutions" class="nav d-flex flex-column" >
                                                <li id="menu-item-49409" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49409">
                                                <a href="#">
                                                        Publishers
                                                    </a>
                                                </li>
                                                <li id="menu-item-49410" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49410">
                                                <a href="#">
                                                        Advertisers
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="col col-6 col-sm-3 mb-2">
                                    <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_resources_navigation">
                                        <div class="nav-header">
                                            Resources
                                        </div>
                                        <div class="menu-footer-resources-navigation-container">
                                            <ul id="menu-footer-resources-navigation" class="nav d-flex flex-column" >
                                                <li id="menu-item-337" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-337">
                                                <a href="#">Blog</a>
                                                </li>
                                                <li id="menu-item-336" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-336">
                                                <a href="#">Webinars</a>
                                                </li>
                                                <li id="menu-item-47821" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-47821">
                                                <a href="#">Case Studies</a>
                                                </li>
                                                <li id="menu-item-63305" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-63305">
                                                <a href="#">Data Insights</a>
                                                </li>
                                                <li id="menu-item-47822" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-47822">
                                                <a href="#">Help Center</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="col col-6 col-sm-3 col-lg-2">
                                    <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_company_navigation">
                                        <div class="nav-header">
                                            Company
                                        </div>
                                        <div class="menu-footer-company-navigation-container">
                                            <ul id="menu-footer-company-navigation" class="nav d-flex flex-column" >
                                                <li id="menu-item-47827" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-47825 current_page_item menu-item-47827">
                                                <a href="#">About</a>
                                                </li>
                                                <li id="menu-item-60548" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-60548">
                                                <a href="#">Press</a>
                                                </li>
                                                <li id="menu-item-47828" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-47828">
                                                <a href="#">Careers</a>
                                                </li>
                                                <li id="menu-item-338" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-338">
                                                <a href="#">Contact</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="footer-bottom">
                <div class="container">
                    <div class="row flex-row">
                        <div class="col col-12 col-lg-4 text-center text-md-left d-flex align-items-center justify-content-center justify-content-md-start">
                            <div class="nav nav-legal py-1 py-lg-0">
                                <div class="footer-nav-wrapper" id="footer_legal_navigation">
                                    <div class="menu-footer-legal-navigation-container">
                                        <ul id="menu-footer-legal-navigation" class="nav d-flex flex-row" >
                                           
                                            <li id="menu-item-47844" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-47844">
                                            <a href="#">Terms &amp; Conditions</a>
                                            </li>
                                            <li id="menu-item-47845" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-47845">
                                            <a href="#">Privacy</a>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col col-12 col-md-6 col-lg-4 text-left text-md-right align-items-center justify-content-center d-flex justify-content-md-start justify-content-lg-center">
                            <div class="legal text-center text-md-left text-lg-center py-1 py-lg-0">
                                © 2021 competes. All Rights Reserved.
                            </div>
                        </div>
    
                        <div class="col col-12 col-md-6 col-lg-4 text-center text-md-right">
                            <div class="nav nav-social text-md-right py-1 py-lg-0">
                                <a class="social-icon-link" href="#" target="_blank" title="Twitter">
                                    <svg class="svg-inline--fa fa-twitter fa-w-16" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path
                                            fill="currentColor"
                                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                        ></path>
                                    </svg>
                                   
                                    <span class="sr-only">Twitter (External link)</span>
                                </a>
                                <a class="social-icon-link" href="#" target="_blank" title="LinkedIn">
                                    <svg
                                        class="svg-inline--fa fa-linkedin fa-w-14"
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fab"
                                        data-icon="linkedin"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512"
                                        data-fa-i2svg=""
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                                        ></path>
                                    </svg>
                                   
                                    <span class="sr-only">LinkedIn (External link)</span>
                                </a>
                                <a class="social-icon-link" href="#" target="_blank" title="FaceBook">
                                    <svg
                                        class="svg-inline--fa fa-facebook fa-w-16"
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fab"
                                        data-icon="facebook"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        data-fa-i2svg=""
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                                        ></path>
                                    </svg>
                                  
                                    <span class="sr-only">FaceBook (External link)</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div class="row -center hero-row popupde">
    <div class="close2">
        <a class="close-atag" href="javascript:void(0);">
            <img src="assets/images/xlose.png" />
        </a>
    </div>
<img class="feature-callout__img" src="https://luna1.co/005100.png" alt="Image of team" width="112" />
            <div class="signupForm-homepage-center col-xsmall-12 col-small-8 col-small-offset-2 col-medium-10 col-medium-offset-1 col-large-8 col-large-offset-2 col-xlarge-6 col-xlarge-offset-3">
                <h4 class="heading -spacing-2 -h3">Sign up</h4>
                <p class="subheading -spacing-1 hero-legal-copy">
                    By signing up, I agree to the Asana <a href="/terms#privacy-policy" class="css-1kfjk3x-Link">Privacy Policy</a> and <a href="/terms#terms-of-service" class="css-1kfjk3x-Link">Terms of Service</a>.
                </p>
                <form action="get-started/verify" method="get" novalidate="" class="signupForm hidden-logged-in signupForm-homepage-center" __bizdiag="1239199729" __biza="WJ__">
                    <div class="signupForm-container">
                        <div class="signupForm-row">
                        <div class="form-group">
                                
                                <input type="email" name="e" required  class="form-control" placeholder="name@company.com" id="work-email" />
                            </div>

                            <div class="submit-btn">
                                <button id="go-to-nextStep"  onClick={this.toggleBox} class="btn btn-lg btn-flat btn-block btn-primary">Next</button>
                            </div>
                        </div>
                    </div>
                </form>
                
            </div>
        </div>
    
        </div>
        );
    }
}

export default Careers;