import React, { useState } from "react";


export default function Apps(props) {

    function shoot(e) {
      var el = document.getElementById('op1');
      el.classList.remove('_1fqn0');
      var el2 = document.getElementById('op2');
      el2.classList.remove('_1fqn0');
      e.currentTarget.classList.toggle('_1fqn0')
      }

      function shoot1(e) {

        var el = document.getElementById('op');
        el.classList.remove('_1fqn0');
        var el2 = document.getElementById('op2');
        el2.classList.remove('_1fqn0');
        e.currentTarget.classList.toggle('_1fqn0')

     }
        function shoot2(e) {

            var el = document.getElementById('op1');
            el.classList.remove('_1fqn0');
            var el2 = document.getElementById('op');
            el2.classList.remove('_1fqn0');
            e.currentTarget.classList.toggle('_1fqn0')
            
            }
     function openmenu(e){
        // alert(222); 
         var el = document.getElementById('sidebarmenu');
         el.classList.toggle('opensidebar')
         var el2 = document.getElementsByClassName('_2THv1')[0]
         el2.classList.toggle('_8Oh3q') 
         var el21 = document.getElementsByClassName('_2Ei7e')[0]
         el21.classList.toggle('addtra')
     }
     
     function openconfig(e){
        var el2 = document.getElementsByClassName('configdrop')[0]
        el2.classList.toggle('show') 
        var el2 = document.getElementsByClassName('menuwadd')[0]
        el2.classList.toggle('dropconfigsub1') 
     }

     function navclick(e){
        
       var str = e.currentTarget.href;
       var res = str.split("#");
       var el2 = document.getElementsByClassName('maintab')[0]
       el2.classList.remove('nonediv')
        el2.classList.add('nonediv')
       
        var btnContainer = document.getElementById("card-header-pills");
        var btns = btnContainer.getElementsByClassName("nav-link");
        var btnContainer1 = document.getElementById("maintab");
        var btns1 = btnContainer1.getElementsByClassName("opentab");

    for (var i = 0; i < btns1.length; i++) {
        btns1[i].classList.remove("opentab");

    }
    for (var i = 0; i < btns.length; i++) {
        
        btns[i].classList.remove("active");
        
    
    }
  //alert(res[1]);
        var el = document.getElementById(res[1])
        el.classList.add('opentab')
        //navRef.current.classList.remove("active");
         e.currentTarget.classList.add('active')
     }

     
     var fileref = document.createElement("link");
fileref.rel = "stylesheet";
fileref.type = "text/css";
fileref.href = "/assets/styles/stylesnewone.css";
document.getElementsByTagName("head")[0].appendChild(fileref)
  
  return (
    <>
   <div class="page-blockpro">
   <header class="header-blockpro">
        
        <nav class="navbar navbar-expand-lg">
            <div class="container-fluid">
                <a class="navbar-brand" href="#"><img src="assets/images/logo-dark.svg" alt="Cryptlight ICO Landing Page WordPress Theme" class="logo_desktop" /></a>
                 <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mb-2 mb-lg-0">
                        <li class="nav-item"><a class="nav-link active" aria-current="page" href="#">Home</a></li>
                        <li class="nav-item"><a class="nav-link" href="#">About</a></li>
                        <li class="nav-item"><a class="nav-link" href="#">Why ICo</a></li>
                        
                        
                    </ul>
                
               
                <div class="register_btns">
                    <a id="href=javascript:void(0)">
                    <button type="button" class="bttn login" onclick="location.href='#">Log in</button>
                    </a>
                    <a id="href=javascript:void(0)">
                    <button id="request-invite" type="button" class="bttn started active" onclick="#">Contact Us</button>
                    </a>
                </div></div>

            </div>
        </nav>
        </header>


        <div class="ova-banner">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    
                    <h2 class="ova-heading ">Powering Data for the new equity blockchain. </h2>
                    <p class="ova-heading-description">The platform helps investors to make easy to purchase and sell their tokens</p>
                    <a id="href=javascript:void(0)" class="btn">Log in</a>
                    <a id="href=javascript:void(0)" class="btn">Contact Us</a>
                    </div>
                
                <div class="col-md-6">
                    
                </div>
            </div>
        </div></div>

        <div class="howit_wrapper">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12"><h2 class="sub-heading">More cool things we've put into exploreos for you…</h2></div>
                </div>
                 <div class="row">
                    <div class="col-md-4">
                        <div class="feature-card">
                            <div class="feature-card_heading">
                            <div class="feature-icon_wrapper"><img src="assets/images/icon_chest.svg" alt="" /></div>
                            <h3>Rewards</h3></div>
                            <div class="feature-card_body">
                            <p class="feature-card_text">Complete Watch-n-Play missions and competing for skins and prizes</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="feature-card">
                            <div class="feature-card_heading">
                            <div class="feature-icon_wrapper"><img src="assets/images/icon_trophy-green.svg" alt="" /></div>
                            <h3>Esports</h3></div>
                            <div class="feature-card_body">
                            <p class="feature-card_text">Easily follow the teams you love across games and be part of their fandom</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">                        
                        <div class="feature-card">
                            <div class="feature-card_heading">
                            <div class="feature-icon_wrapper"><img src="assets/images/icon_sun-yellow.svg" alt="" /></div>
                            <h3>Light Mode</h3></div>
                            <div class="feature-card_body">
                            <p class="feature-card_text">We’re dark mode by default, but for those that want light mode—we gotchu </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
   
   
     </>
            
            );
}

