import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';
import Trends from './partials/Trends';


class Gmx extends React.Component {
   componentWillMount () {
      const script = document.createElement("script");
   
      script.src = "/assets/js/slick.min.js";
     // script.src = "/assets/js/main.js";
      script.async = true;
   
      document.body.appendChild(script);

      const script1 = document.createElement("script");
   
     // script1.src = "/assets/js/slick.min.js";
      script1.src = "/assets/js/main.js";
      script1.async = true;
   
      document.body.appendChild(script1);
    }
   render(){
 
    return(
        <div class="gmxpage">
        <div class="main-banner homepage ">
        <div class="container bo">
        <img src="assets/images/Web-header-Mobile-1.svg" class="middlediv mobi container" />
                <img src="assets/images/top-banner-below-3-3px.svg" class="middlediv desk container" />
                </div>
                <div class="container">
                <img src="assets/images/Web-header-Mobile-1.svg" class="middlediv mobi container" />
                <img src="assets/images/top-banner-below-3-3px.svg" class="middlediv desk container" />
                <header class="main-header d-flex justify-content-between">
               <div class="site-logo">
                  <a href="javascript:void(0)">competes</a>
               </div>
               <div class="menu-toggle-icon">
                  <span class="hamburger-inner"></span>
               </div>
               <div class="site-login">
                  <div class="menu-toggle-bg"></div>
                  <div class="site-login-inner">
                     <div class="site-logo menu-with-logo">
                        <a href="javascript:void(0)">competes</a>
                     </div>
                      
                     
                      <NavLink to="/signals" className="mr-4 top-link mobi" >Signals</NavLink>
                     
                     <NavLink to="/solutions" className="mr-4 top-link">Solutions</NavLink>
                     <NavLink to="/signals" className="mr-4 top-link desk">Signals</NavLink>
                    
                     <NavLink to="/gmx/about" className="mr-4 top-link">About</NavLink> 
                     
                     
                  </div>
               </div>
            </header>
                
            <div class="row justify-content-center gmxupper">
               <div class="col-md-10">
               <div class="banner-content text-center">
                     <h1 class="banner-title gmx">Decentralized Options Exchange.</h1>
                     <div class="banner-description">
                       
                        <p>Trade BTC, ETH and other top cryptocurrencies options <br/> on-chain directly from your wallet</p>
                     </div>
                     <div class="banner-action" ><form action="/">
                     <div class="input-group">
                        
                           
                     <div class="input-group-append">
                              
                              <NavLink to="/" className="btn btn-lg btn-flat btn-primary">
                              Start Trading
                              <svg focusable="false" class="ibm-duo-u--link-icon--arrow-right" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" slot="icon"><path d="M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z"></path></svg>
                              </NavLink> 
                           </div>
                          
                           </div>
                           </form>
                           </div>
                     </div>
               
               
               </div> </div>
               
              
                </div>
                </div>

                <div class="call-to-action container">
                <div class="container">
                <div class="row align-items-center">
                <div class="col-md-8">
                    <div role="listitem" class="swiper-slide w-dyn-item fir">
<div class="bitcoin-card grey">
<div class="cardckl">
<div class="card-headerbk">
<div class="icon-bitcoin w-embed">
<img class="icon-bitcoin" 
src="https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880" alt="icon crypto" />
	
</div>
<div class="wrapper-text">
<p class="p-large-white">Ethereum</p>
<p class="psymbol">eth</p>
</div>
</div>
<div class="wrapper-price">
<h4 class="h4-small price">$</h4>
<h4 class="h4-small currency">4.226.55</h4>
</div><div class="wrapper-grid-stats">

<div class="grid-stats-coin-card">
<p class="p-small">24H %</p>
<div class="wrapper-24h">
<p class="p-small green">0.99</p>
<p class="p-small green">%</p>
</div>
</div>
<div class="grid-stats-coin-card hide">
<p class="p-small">VOL</p>
<div class="wrapper-marketcap">
<p class="p-small">$</p>
<p class="p-small">13509032674</p>
</div>
</div>
</div>
</div></div></div>
<div role="listitem" class="swiper-slide w-dyn-item">
<div class="bitcoin-card grey">
<div class="cardckl">
<div class="card-headerbk">
<div class="icon-bitcoin w-embed">
<img class="icon-bitcoin" 
src="https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579" alt="icon crypto" />
	
</div>
<div class="wrapper-text">
<p class="p-large-white">Bitcoin</p>
<p class="psymbol">btc</p>
</div>
</div>
<div class="wrapper-price">
<h4 class="h4-small price">$</h4>
<h4 class="h4-small currency">64,906</h4>
</div><div class="wrapper-grid-stats">

<div class="grid-stats-coin-card">
<p class="p-small">24H %</p>
<div class="wrapper-24h">
<p class="p-small green">1.95</p>
<p class="p-small green">%</p>
</div>
</div>
<div class="grid-stats-coin-card hide">
<p class="p-small">VOL</p>
<div class="wrapper-marketcap">
<p class="p-small">$</p>
<p class="p-small">30118398776</p>
</div>
</div>
</div>
</div></div></div>
<div role="listitem" class="swiper-slide w-dyn-item">
<div class="bitcoin-card grey">
<div class="cardckl">
<div class="card-headerbk">
<div class="icon-bitcoin w-embed">
<img class="icon-bitcoin" 
src="https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880" alt="icon crypto" />
	
</div>
<div class="wrapper-text">
<p class="p-large-white">Ethereum</p>
<p class="psymbol">eth</p>
</div>
</div>
<div class="wrapper-price">
<h4 class="h4-small price">$</h4>
<h4 class="h4-small currency">4,643.66</h4>
</div><div class="wrapper-grid-stats">

<div class="grid-stats-coin-card">
<p class="p-small">24H %</p>
<div class="wrapper-24h">
<p class="p-small green">0.99</p>
<p class="p-small green">%</p>
</div>
</div>
<div class="grid-stats-coin-card hide">
<p class="p-small">VOL</p>
<div class="wrapper-marketcap">
<p class="p-small">$</p>
<p class="p-small">13509032674</p>
</div>
</div>
</div>
</div></div></div>
                    </div>
                   
                    <div class="col-md-4">
                        <div class="buttonstart">
                        <NavLink to="/" className="btn btn-lg btn-flat btn-dark"> Start Trading
                        <svg focusable="false" class="ibm-duo-u--link-icon--arrow-right" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" slot="icon"><path d="M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z"></path></svg>
                        </NavLink> </div>
                    </div>
                </div>
                </div>
                </div>
                
                <div class="logodiv">
                    <h4 class="build">Built on</h4>
                    <p>Lightning-fast and scalable options trading</p>
                    <div class="img-box2">
                    <img src="assets/images/fj_customer.png" alt=""  />
                </div>
                <div class="img-box2">
                    <img src="assets/images/ax_customer.png" alt=""  />
                </div>
                <div class="img-box2">
                    <img src="assets/images/fj_customer.png" alt=""  />
                </div>
                <div class="img-box2">
                    <img src="assets/images/ax_customer.png" alt=""  />
                </div>

                </div>
                
                <div class="connecting-trends first-trends">
         <div class="container">
            <div class="row align-items-center rotate-in-mobile">
            <div class="col-md-6">
            <Trends />
                     </div>
            
            
            <div class="col-md-6">
               <div class="section-content">
                <h2>Connecting trends <br />with best-in-class <br />providers</h2>
                  <p>Simplify your growth stack with 250+ best-in-class integrations, built with enterprise-class reliability. Reduce implementation time and get to market at the pace of your customers.</p>
                  <a href="javascript:void(0)" class="btn-link green">View All Integrations &nbsp; ></a>
               </div> </div>
            </div>

            </div>
      </div>

      <div class="connecting-trends sect" >
         <div class="container">
            <div class="row align-items-center">
               <div class="col-md-6">
                  <div class="section-content">
                  <h2>Connecting trends <br />with best-in-class <br />providers</h2>
                     <p>Simplify your growth stack with 250+ best-in-class integrations, built with enterprise-class reliability. Reduce implementation time and get to market at the pace of your customers.</p>
                     <a href="javascript:void(0)" class="btn-link green">View All Integrations &nbsp; ></a>
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="logo-list">
                     <div class="row c-home-notifications">
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/facebook-logo.png" alt="Facebook" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/google-ads.png" alt="Google Ads" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/snapchat.png" alt="snapchat" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/google-marketing-platform.png" alt="Google marketing platform" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/twitter-logo.png" alt="Twitter" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/braze-logo.png" alt="Braze" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/google-big-query.png" alt="Google Big Query" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/amazon-kinesis.png" alt="Amazon Kinesis" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/salesforce-logo.png" alt="Salesforce Marketing Cloud" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/Mixpanel_logo.png" alt="Mix Panel" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/remerge-logo.png" alt="Remerge" /></div>
                        </div>
                        <div class="col-6 col-md-4">
                           <div class="logo-wrap"><img src="assets/images/Live-Ramp-Logo.png" alt="Live Ramp" /></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="intro-sections">
         <section class="intro-section">
            <div class="container">
               <div class="row">
                  <div class="col-md-7 revercediv">
                     <img src="assets/images/workflow-collaboration@4x-66d002b4.png" alt="placeholder-image" />
                  </div>
                  <div class="col-md-5">
                     <strong class="label">Growth Marketers</strong>
                     <h2>Drive Growth, Faster</h2>
                     <p>Accelerate growth, retention, and monetization by improving data quality and simplifying data connections across all of your channels and partners in real time.</p>
                     <a href="javascript:void(0)" class="btn-link gray">Learn More &nbsp; ></a>
                  </div>
               </div>
            </div>
         </section>
      
      </div>
      
      

<div class="g-call-to-action variant-compact theme-light">
    <div class="g-grid-container">
        <h2 data-testid="heading" class="g-type-display-2">Ready to get started?</h2>
        <div class="content-and-links">
            <p data-testid="content" class="g-type-body-large">Request a demo or talk to our technical sales team to answer your questions.</p>
            <div data-testid="links" class="links">
                <a class="g-btn size-medium variant-primary background-light" data-ga-button="contact-sales" href="#"><span class="text g-type-buttons-and-standalone-links">Start Trading</span></a>
                
            </div>
        </div>
    </div>
</div>


   
      <footer class="footer footer-site wrap-site-footer bg-darker">
    <div class="wrap-site-footer-inside">
        <div class="footer-top">
            <div class="container">
                <div class="row flex-row row-footer-nav justify-content-start justify-content-md-between">
                    <div class="col col-12 col-lg-4 col-xl-3 my-1 order-last order-lg-first">
                        <div class="organization py-2 py-md-1 py-lg-0" >
                            <meta itemprop="name" content="Competes" />
                            <figure class="logo">
                               
                                <a aria-current="page" class="active" href="/">Competes</a>
                               
                            </figure>
                            <div>
                                
                                <div id="choice-footer-msg" class="choice-footer-msg ccpa-msg-added">
                                    We use cookies and other data collection technologies to provide the best experience for our customers. You may request that your data not be shared with third parties here:
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col col-12 col-lg-8 my-1 order-first order-lg-last">
                        <div class="row">

                            <div class="col col-6 col-sm-3 mb-3">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_solutions_navigation">
                                    <div class="nav-header">
                                        Solutions
                                    </div>
                                    <div class="menu-footer-solutions-container">
                                        <ul id="menu-footer-solutions" class="nav d-flex flex-column" >
                                            <li id="menu-item-49409" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49409">
                                            <a href="#">
                                                    Publishers
                                                </a>
                                            </li>
                                            <li id="menu-item-49410" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49410">
                                            <a href="#">
                                                    Advertisers
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-3 mb-3">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_resources_navigation">
                                    <div class="nav-header">
                                        Resources
                                    </div>
                                    <div class="menu-footer-resources-navigation-container">
                                        <ul id="menu-footer-resources-navigation" class="nav d-flex flex-column" >
                                            <li id="menu-item-337" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-337">
                                            <a href="#">Blog</a>
                                            </li>
                                            <li id="menu-item-336" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-336">
                                            <a href="#">Webinars</a>
                                            </li>
                                            <li id="menu-item-47821" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-47821">
                                            <a href="#">Case Studies</a>
                                            </li>
                                            <li id="menu-item-63305" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-63305">
                                            <a href="#">Data Insights</a>
                                            </li>
                                            <li id="menu-item-47822" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-47822">
                                            <a href="#">Help Center</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-3 col-lg-3">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_company_navigation">
                                    <div class="nav-header">
                                        Company
                                    </div>
                                    <div class="menu-footer-company-navigation-container">
                                        <ul id="menu-footer-company-navigation" class="nav d-flex flex-column" >
                                            <li id="menu-item-47827" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-47825 current_page_item menu-item-47827">
                                            <a href="#">About</a>
                                            </li>
                                            <li id="menu-item-60548" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-60548">
                                            <a href="#">Press</a>
                                            </li>
                                            <li id="menu-item-47828" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-47828">
                                            <a href="#">Careers</a>
                                            </li>
                                            <li id="menu-item-338" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-338">
                                            <a href="#">Contact</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-3 col-lg-3">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_company_navigation">
                                    
                                    <div class="menu-footer-company-navigation-container">
                                        <ul id="menu-footer-company-navigation" class="nav d-flex flex-column" >
                                            <li id="menu-item-47827" class="soc menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-47825 current_page_item menu-item-47827">
                                            <svg class="svg-inline--fa fa-twitter fa-w-16" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                    <path
                                        fill="currentColor"
                                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                    ></path>
                                </svg> Twitter
                                            </li>
                                            <li id="menu-item-60548" class="soc menu-item menu-item-type-custom menu-item-object-custom menu-item-60548">
                                            <img src="assets/images/reddit.svg"  class="Sitemap__StyledIcon-uyke27-8 drcgmZ" /> Reddit
                                            </li>
                                            <li id="menu-item-47828" class="soc menu-item menu-item-type-post_type menu-item-object-page menu-item-47828">
                                            <svg
                                    class="svg-inline--fa fa-facebook fa-w-16"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="facebook"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    data-fa-i2svg=""
                                >
                                    <path
                                        fill="currentColor"
                                        d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                                    ></path>
                                </svg> FaceBook
                                            </li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</footer>

<div class="row -center hero-row popupde">
    <div class="close2">
        <a class="close-atag" href="javascript:void(0);">
            <img src="assets/images/xlose.png" />
        </a>
    </div>
<img class="feature-callout__img" src="https://luna1.co/005100.png" alt="Image of team" width="112" />
            <div class="signupForm-homepage-center col-xsmall-12 col-small-8 col-small-offset-2 col-medium-10 col-medium-offset-1 col-large-8 col-large-offset-2 col-xlarge-6 col-xlarge-offset-3">
                <h4 class="heading -spacing-2 -h3">Sign up</h4>
                <p class="subheading -spacing-1 hero-legal-copy">
                    By signing up, I agree to the Asana <a href="/terms#privacy-policy" class="css-1kfjk3x-Link">Privacy Policy</a> and <a href="/terms#terms-of-service" class="css-1kfjk3x-Link">Terms of Service</a>.
                </p>
                <form action="get-started/verify" method="get" novalidate="" class="signupForm hidden-logged-in signupForm-homepage-center" __bizdiag="1239199729" __biza="WJ__">
                    <div class="signupForm-container">
                        <div class="signupForm-row">
                        <div class="form-group">
                                
                                <input type="email" name="e" required  class="form-control" placeholder="name@company.com" id="work-email" />
                            </div>

                            <div class="submit-btn">
                                <button id="go-to-nextStep"  onClick={this.toggleBox} class="btn btn-lg btn-flat btn-block btn-primary">Next</button>
                            </div>
                        </div>
                    </div>
                </form>
                
            </div>
        </div>
    </div>
    );
}
}

export default Gmx;
