import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// components
import Layout from "./Layout";
import Layoutold from "./Layoutold"; 

// pages
import Error from "../pages/error";
import Login from "../pages/login";

import MainPage from "../pages";
import Inviz from "../pages/inviz";
import NotFoundPage from "../pages/404";
import LoginPage from "../pages/login";
import SignUpPage from "../pages/signup-new";
import VerifyPage from "../pages/verify";
import GmxPage from "../pages/gmx";
import Blockproducers from "../pages/blockproducers";
import Blockproducersmain from "../pages/blockproducersmain";
import Blockproducersmainhome2 from "../pages/blockproducersmainHome2";
import Blockproducersmainhome3 from "../pages/blockproducersmainHome3";
import Blockproducersmainhome4 from "../pages/blockproducersmainHome4";
import Blockproducersprivacy from "../pages/blockproducersprivacy";
import Blockproducersterms from "../pages/blockproducersterms";
import Blockproducersabout from "../pages/blockproducersabout";
import Blockproducerscontact from "../pages/blockproducerscontact";
import InterblockchainM from "../pages/interblockchain";
import InterblockchainSignup from "../pages/signup-blockproducers";
import InterestBlock from "../pages/interest";
import CompanyPage from "../pages/company";
import ProAboutPage from "../pages/proabout";
import SigninPage from "../pages/signin";
import AboutusPage from "../pages/aboutus"; 
import SolutionsPage from "../pages/solutions";
import SignalsPage from "../pages/signals";
import CareersPage from "../pages/careers";
import PrivacyPage from "../pages/privacynew";
import ChartPageLine from "../pages/chartline";
import ChartPageLine2 from "../pages/chartline2";
import Upsignaldashboard from "../pages/upsignaldashboard";
import BPrivacy from "../pages/bprivacy";
import BTerms from "../pages/bterms";
// context
import { useUserState } from "../context/UserContext";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();

  return (
    <Router>
      <Switch>
         <Route exact path="/" component={MainPage}></Route>
           <Route exact path="/404"  component={NotFoundPage}></Route>
           <Route exact path="/login"  component={LoginPage}></Route>
           <Route exact path="/company"  component={CompanyPage}></Route>
           <Route exact path="/blockproducers/about"  component={ProAboutPage}></Route>
           <Route exact path="/gmx/about"  component={AboutusPage}></Route>
           <Route exact path="/blockproducers/privacy"  component={BPrivacy}></Route>
           <Route exact path="/blockproducers/terms"  component={BTerms}></Route>
           <Route exact path="/solutions"  component={SolutionsPage}></Route>
           <Route exact path="/signals"  component={SignalsPage}></Route>
           <Route exact path="/interblockchain"  component={InterblockchainM}></Route>
           <Route exact path="/blockproducers/interest"  component={InterestBlock}></Route>
           <Route exact path="/blockproducers/get-started/signup"  component={InterblockchainSignup}></Route>
           <Route exact path="/blockproducers/login"  component={SigninPage}></Route>
           <Route exact path="/get-started/signup"  component={SignUpPage}></Route>
           <Route exact path="/get-started/verify"  component={VerifyPage}></Route>
           <Route exact path="/privacy"  component={PrivacyPage}></Route>
           <Route exact path="/careers"  component={CareersPage}></Route>
           <Route exact path="/gmx"  component={GmxPage}></Route>
           <Route exact path="/blockproducers2"  component={Blockproducers}></Route>
           <Route exact path="/blockproducers"  component={Blockproducersmain}></Route>
           <Route exact path="/blockproducers/home2"  component={Blockproducersmainhome2}></Route>
           <Route exact path="/blockproducers/home4/contact"  component={Blockproducerscontact}></Route>
           <Route exact path="/blockproducers/home3"  component={Blockproducersmainhome3}></Route>
           <Route exact path="/blockproducers/home4"  component={Blockproducersmainhome4}></Route>
           <Route exact path="/blockproducers/home4/privacy"  component={Blockproducersprivacy}></Route>
           <Route exact path="/blockproducers/home4/terms"  component={Blockproducersterms}></Route>
           <Route exact path="/blockproducers/home4/about"  component={Blockproducersabout}></Route>
           <Route exact path="/chart1"  component={ChartPageLine}></Route>
           <Route exact path="/chart2"  component={ChartPageLine2}></Route>
           <Route exact path="/trial/dashboard"  component={Layoutold}></Route>
           <Route exact path="/trial/vans"  component={Layoutold}></Route>
           <Route exact path="/trial/privacy"  component={Layout}></Route>
           <Route exact path="/trial/security"  component={Layout}></Route>
           <Route exact path="/trial/allowlist"  component={Layout}></Route>
           <Route exact path="/trial/parentalcontrol"  component={Layout}></Route>
           <Route exact path="/trial/analytics"  component={Layout}></Route>
           <Route exact path="/trial/denylist"  component={Layout}></Route>
           <Route exact path="/trial/logs"  component={Layout}></Route>
           <Route exact path="/trial/setup"  component={Layout}></Route>
           <Route exact path="/upsignal/dashboard"  component={Upsignaldashboard}></Route>
           <Route exact path="/trial/account"  component={Layout}></Route>
           <Route exact path="/inviz" component={Inviz}></Route>
        
         <Route component={Error} />
      </Switch>
    </Router>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/trial",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
