import React,{Component} from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


class Trends extends Component{
    render(){
        var settings = {
         pauseOnHover:false,
         pauseOnFocus:false,
         draggable:false,
         autoplay: true,
         infinite: true,
         dots: false,
         arrows: false,
         vertical: true,
         slidesToShow: 3,
         slidesToScroll: 1,
         swipeToSlide:true,
         speed: 1000,
         autoplaySpeed: 2000,
         slidesToScroll:-1
          };
        return(
            <div className="logo-list">
                <div className="c-home-notifications notifications-slider">
                    
                    <div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div>    <div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div>   <div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div>  <div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div>
<div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div><div class="notification-bubble ">
                           <div class="top">
                           <img src="https://uchatr.com/assets/images/c-home-bret.png" class="logo" loading="lazy" width="46px" alt="Bret" srcset="" />
                              <div class="notification-bubble-text">
                                 <p><span>bret@<span class="reveal-domain">quip.com</span></span> has changed jobs</p>
                                 <p class="light">Updated recently</p>
                              </div>
                              <img src="https://uchatr.com/assets/images/home-notification-chevron-right.svg" class="chevron" loading="lazy" width="9px" height="18px" alt="chevron right" />
</div></div>        
                </div>
            </div>
        )
    }
}
export default Trends;