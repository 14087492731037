import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';
//import Trends from './partials/Trends';


class Solutions extends React.Component {
    
    componentWillMount () {
        
        const script = document.createElement("script");
     
        script.src = "//assets/js/menutoggle.js";
       
        script.async = true;
     
        document.body.appendChild(script);
       
      }
   render(){
 
    return(
        <div>
        <div class="main-banner solutionbg term">
                <div class="container">
                <header class="main-header d-flex justify-content-between">
               <div class="site-logo">
                  
                  <NavLink to="/">competes</NavLink>
               </div>
               <div class="menu-toggle-icon">
                  <span class="hamburger-inner"></span>
               </div>
               <div class="site-login">
                  <div class="menu-toggle-bg"></div>
                  <div class="site-login-inner">
                     <div class="site-logo menu-with-logo">
                        <a href="javascript:void(0)">competes</a>
                     </div>
                      
                     <a href="javascript:void(0)" className="mr-4 top-link mobi" id="get-start">Get Started</a>
                      <NavLink to="/signals" className="mr-4 top-link mobi" >Signals</NavLink>
                     
                     <NavLink to="/solutions" className="mr-4 top-link">Solutions</NavLink>
                     <NavLink to="/signals" className="mr-4 top-link desk">Signals</NavLink>
                     <NavLink to="/login" className="mr-4 top-link mobi">Login</NavLink>
                     <NavLink to="/company" className="mr-4 top-link">Company</NavLink> 
                     <NavLink to="/login" className="mr-4 top-link desk">Login</NavLink>
                     <NavLink to="/get-started/signup" className="btn btn-lg btn-flat btn-outline ml-3 desk get33">Get Started</NavLink>
                  </div>
               </div>
            </header>
                
            
               <section class="hero-sec hero-choice-sec bs-2x-padding d-flex flex-column justify-content-center plus-canvas-wrapper">
    
    <div class="container z-10">
        <div class="row flex-row d-flex justify-content-start align-items-center">
            <div class="col col-text col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 mt-4 mb-4">
                <div class="hero-text_wrapper">
                    <h1 class="headline"> Simplify User Consent</h1>
                    <div>
                        <p class="text-gray text">Supporting User consent choice across GDPR, ePrivacy Directive and CCPA</p>
                    </div>
                    <div class="section-btns footer-btns topseca">
                        
                        <NavLink to="/" className="btn btn-outline-primary icon icon-after button-custom">Get Started
                             <svg focusable="false" class="ibm-duo-u--link-icon--arrow-right" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" slot="icon"><path d="M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z"></path></svg>
                             </NavLink>
                    </div>

                    <div class="quote quote-hero hidden-md-down inline-image-quote w-100">
                        <div class="row">
                            <div class="col-3 col-3 col-lg-2">
                                <figure class="figure-qutoe">
                                    <img
                                        src="/assets/images/sharon-weiss-media-minute-sq.jpg"
                                        
                                    />
                                </figure>
                            </div>
                            <div class="col-9 col-lg-10 d-flex align-items-center">
                                <blockquote class="blockquote">
                                    <p class="mb-0 quote-text">
                                        "Our vendor whitelist used to be a painful and manual process that drained time and resources. We now use competes Choice Premium."
                                    </p>
                                   
                                </blockquote>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>

            <div class="col col-img col-12 col-sm-12 col-md-7 col-lg-7 col-xl-8 mt-4 mb-4 dek">
                <div class="choice-hero-laptop__wrapper">
                    <figure class="choice-hero-laptop__figure">
                        <img
                            width="1300"
                            height="786"
                            src="/assets/images/hero-solutions.png"
                           
                        />
                    </figure>
                </div>
            </div>
        </div>
      
    </div>
</section>
 </div>
 <div >
    <img class="imgrightsol" alt="" src="/assets/images/media-pattern.svg"
    />
</div>     </div>
                <div class="call-to-action container combg">
                <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-7">
                        <div class="pl-5">
                            <h2 class="freeh2">Free Weekly Prospects</h2>
                            <p class="pclass">Get a weekly email telling you which of your target accounts are researching topics relevant to your business.</p>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="buttonstart">
                        <NavLink to="/get-started/signup" className="btn btn-lg btn-flat btn-dark">Start Now
                        <svg focusable="false" class="ibm-duo-u--link-icon--arrow-right" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" slot="icon"><path d="M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z"></path></svg>
                        </NavLink> </div>
                    </div>
                </div>
                </div>
                </div>
                <div>
                <section class="stat-sec stat-choice-sec bs-2x-padding" id="stats">
    <div class="container">
        <div class="row flex-row d-flex justify-content-center">
            <div class="col col-stat col-11 col-sm-10 col-md-4">
                <div class="stat_wrapper">
                    <hr />
                    <div class="stat-num">
                        62k+
                    </div>
                    <div class="stat-desc">
                        Active domains using competes Choice.
                    </div>
                </div>
            </div>
            <div class="col col-stat col-11 col-sm-10 col-md-4">
                <div class="stat_wrapper">
                    <hr />
                    <div class="stat-num">
                        25 Billion+
                    </div>
                    <div class="stat-desc">
                        Global consent signals.
                    </div>
                </div>
            </div>
            <div class="col col-stat col-11 col-sm-10 col-md-4">
                <div class="stat_wrapper">
                    <hr />
                    <div class="stat-num">
                        90%+
                    </div>
                    <div class="stat-desc">
                        Average consumer consent rate.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="demo-sec demo-choice-sec demo-choice-tabs-sec bs-2x-padding s1 bg-black bg-demo-tabs-1" id="demo">
    <div class="container padding-top-2 padding-bottom-2">
        <ul class="nav nav-tabs flex-row demo-tabs s2" id="demo-tabs" role="tablist">
            <li class="nav-item nav-label">
                <div>Preview </div>
            </li>
            <li class="nav-item">
                <a
                    href="#choice-premium"
                    class="nav-link active"
                    id="choice-premium-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="choice-premium"
                    aria-selected="true"
                    data-g-event="choice"
                    data-g-action="show choice premium"
                    data-g-label="demo section"
                >
                    Choice Premium
                </a>
                
            </li>
            <li class="nav-item">
                <a href="#choice" class="nav-link" id="choice-tab" data-toggle="tab" role="tab" aria-controls="choice" aria-selected="false" data-g-event="choice" data-g-action="show choice" data-g-label="demo section">Choice</a>
            </li>
        </ul>
        <div class="tab-content" id="tabs-tabContent">
            <div class="tab-pane fade show active" id="choice-premium" role="tabpanel" aria-labelledby="choice-premium-tab">
                <div class="flex-content">
                    <div class="">
                        <div class="row d-flex justify-content-start flex-column-reverse flex-lg-row">
                            <div class="col col-12 col-lg-5 d-flex flex-column justify-content-center">
                                <div class="slide-txt_wrapper">
                                    <h3 class="headline">
                                        Efficiently manage your users consent for GDPR and CCPA
                                    </h3>
                                    <div class="text">
                                        <p></p>
                                        <div class="textp">A one-stop-shop to manage your consent strategy, enabling continued global publishing and relevant advertising.</div>
                                        <p></p>
                                    </div>
                                </div>
                                <div class="section-btns footer-btns">
                                    
                                    <NavLink to="/signup" className="btn btn-outline-primary icon icon-after button-custom">Get Choice Premium
                             <svg focusable="false" class="ibm-duo-u--link-icon--arrow-right" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" slot="icon"><path d="M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z"></path></svg>
                             </NavLink>
                                </div>
                            </div>
                            <div class="col-12 col-lg-7">
                                <div class="demo-item choice-demo">
                                    <div class="choice-demo-item-inside demo-item-inside">
                                        <figure class="laptop-fig">
                                            <img
                                                alt="demo laptop"
                                                src="/assets/images/hero-solutions.png"
                                            />
                                        </figure>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="choice" role="tabpanel" aria-labelledby="choice-tab">
                <div class="flex-content">
                    <div class="">
                        <div class="row d-flex justify-content-start flex-column-reverse flex-lg-row">
                            <div class="col col-12 col-lg-5 d-flex flex-column justify-content-center">
                                <div class="slide-txt_wrapper">
                                    <h3 class="headline">
                                        Compliant business is the&nbsp;goal
                                    </h3>
                                    <div class="text">
                                        A clean, easy-to-understand and customizable user experience.
                                    </div>
                                </div>
                                <div class="section-btns footer-btns">
                                    
                                    <NavLink to="/" className="btn btn-outline-primary icon icon-after button-custom">Get Choice 
                             <svg focusable="false" class="ibm-duo-u--link-icon--arrow-right" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" slot="icon"><path d="M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z"></path></svg>
                             </NavLink>
                                </div>
                            </div>
                            <div class="col-12 col-lg-7">
                                <div class="demo-item choice-demo">
                                    <div class="choice-demo-item-inside demo-item-inside">
                                        <figure class="laptop-fig">
                                        <img
                                                alt="demo laptop"
                                                src="/assets/images/hero-solutions.png"
                                            />
                                        </figure>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section  class="py-4 py-md-6 text-left sectionbottom sol">
    <header class="header-content pt-4 text-center hl-sm">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-8 col-lg-7">
                    <h3 class="headline headline-header">
                        <span class="superheadline">Learn</span>

                        More about competes
                    </h3>
                </div>
            </div>
        </div>
    </header>

    <div class="container" data-section-container-main="zmvjsa">
        <div data-section-row-main="zmvjsa" class="row flex-row justify-content-between">
            <div class="col col-cards col-12 py-2 py-md-4">
                <div class="col-inside col-inside-cards text-left list-">
                    <div class="row flex-row row-cards justify-content-between gutter-lg">
                        <div class="col col-card col-12 col-lg-4">
                            <div class="col-inside col-inside-card h-100 py-2">
                                <a class="wrapper-link card-wrapper-link" href="#" >
                                    <div class="card h-100 d-flex flex-column justify-content-md-between flat transparent no-x-padding no-y-padding text-left hl-xs">
                                        <div class="wrapper-card-content d-flex justify-content-md-between h-100 flex-column">
                                            <figure class="figure figure-cards w-100">
                                                <img
                                                    class="lazy image img-fluid figure-img card-img-top loaded"
                                                    alt="1"
                                                    src="/assets/images/tech-hero-dark.min_-1024x576-1-500x283.jpg"
                                                    
                                                />
                                            </figure>

                                            <div class="card-body d-flex flex-column h-100 justify-content-between">
                                                <div class="d-flex flex-column h-100 justify-content-md-start">
                                                   

                                                    <div class="colk">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                                                        It has survived not only 
                                                        five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                    </div>
                                                </div>

                                                <div class="buttons buttons-cards text-right">
                                                <div class="style_bottom__31EWl">
                                                <span class="style_bottomLabel__3y44J">Integration</span>
                                               
                                            </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col col-card col-12 col-lg-4">
                            <div class="col-inside col-inside-card h-100 py-2">
                                <a class="wrapper-link card-wrapper-link" href="#" >
                                    <div class="card h-100 d-flex flex-column justify-content-md-between flat transparent no-x-padding no-y-padding text-left hl-xs">
                                        <div class="wrapper-card-content d-flex justify-content-md-between h-100 flex-column">
                                            <figure class="figure figure-cards w-100">
                                                <img
                                                    class="lazy image img-fluid figure-img card-img-top loaded"
                                                    alt="1"
                                                    src="/assets/images/magazine-cover-collage-forbes-500x283.jpg"
                                                    
                                                />
                                            </figure>

                                            <div class="card-body d-flex flex-column h-100 justify-content-between">
                                                <div class="d-flex flex-column h-100 justify-content-md-start">
                                                   

                                                    <div class="colk">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                                                        It has survived not only 
                                                        five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                    </div>
                                                </div>

                                                <div class="buttons buttons-cards text-right">
                                                <div class="style_bottom__31EWl">
                                                <span class="style_bottomLabel__3y44J">Integration</span>
                                                
                                            </div>

                                                </div>
                                           
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col col-card col-12 col-lg-4">
                            <div class="col-inside col-inside-card h-100 py-2">
                                <a class="wrapper-link card-wrapper-link" href="#" >
                                    <div class="card h-100 d-flex flex-column justify-content-md-between flat transparent no-x-padding no-y-padding text-left hl-xs">
                                        <div class="wrapper-card-content d-flex justify-content-md-between h-100 flex-column">
                                            <figure class="figure figure-cards w-100">
                                                <img
                                                    class="lazy image img-fluid figure-img card-img-top loaded"
                                                    alt="1"
                                                    src="/assets/images/measure-product-help-center-support-500x283.jpg"
                                                    
                                                />
                                            </figure>

                                            <div class="card-body d-flex flex-column h-100 justify-content-between">
                                                <div class="d-flex flex-column h-100 justify-content-md-start">
                                                  

                                                    <div class="colk">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                                                        It has survived not only 
                                                        five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                    </div>
                                                </div>

                                                <div class="buttons buttons-cards text-right">
                                                <div class="style_bottom__31EWl">
                                                <span class="style_bottomLabel__3y44J">Integration</span>
                                                
                                            </div>

                                                </div>
                                           
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>




    </div>
    <div class="g-call-to-action variant-compact theme-light">
    <div class="g-grid-container">
        <h2 data-testid="heading" class="g-type-display-2">Ready to get started?</h2>
        <div class="content-and-links">
            <p data-testid="content" class="g-type-body-large">Request a demo or talk to our technical sales team to answer your questions.</p>
            <div data-testid="links" class="links">
                <a class="g-btn size-medium variant-primary background-light" data-ga-button="contact-sales" href="#"><span class="text g-type-buttons-and-standalone-links">Contact Sales</span></a>
                <a class="g-btn size-medium variant-secondary background-light" data-ga-button="request-demo" href="#"><span class="text g-type-buttons-and-standalone-links">Request Demo</span></a>
            </div>
        </div>
    </div>
</div>

    <footer class="footer footer-site wrap-site-footer bg-darker">
    <div class="wrap-site-footer-inside">
        <div class="footer-top">
            <div class="container">
                <div class="row flex-row row-footer-nav justify-content-start justify-content-md-between">
                    <div class="col col-12 col-lg-4 col-xl-3 my-1 order-last order-lg-first">
                        <div class="organization py-2 py-md-1 py-lg-0" >
                            <meta itemprop="name" content="competes" />
                            <figure class="logo">
                               
                                <a aria-current="page" class="active" href="/">competes</a>
                               
                            </figure>
                            <div>
                                
                                <div id="choice-footer-msg" class="choice-footer-msg ccpa-msg-added">
                                    We use cookies and other data collection technologies to provide the best experience for our customers. You may request that your data not be shared with third parties here:
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col col-12 col-lg-8 my-1 order-first order-lg-last">
                        <div class="row">
                            

                            <div class="col col-6 col-sm-3 mb-2">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_solutions_navigation">
                                    <div class="nav-header">
                                        Solutions
                                    </div>
                                    <div class="menu-footer-solutions-container">
                                        <ul id="menu-footer-solutions" class="nav d-flex flex-column" >
                                            <li id="menu-item-49409" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49409">
                                            <a href="#">
                                                    Publishers
                                                </a>
                                            </li>
                                            <li id="menu-item-49410" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49410">
                                            <a href="#">
                                                    Advertisers
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-3 mb-2">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_resources_navigation">
                                    <div class="nav-header">
                                        Resources
                                    </div>
                                    <div class="menu-footer-resources-navigation-container">
                                        <ul id="menu-footer-resources-navigation" class="nav d-flex flex-column" >
                                            <li id="menu-item-337" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-337">
                                            <a href="#">Blog</a>
                                            </li>
                                            <li id="menu-item-336" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-336">
                                            <a href="#">Webinars</a>
                                            </li>
                                            <li id="menu-item-47821" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-47821">
                                            <a href="#">Case Studies</a>
                                            </li>
                                            <li id="menu-item-63305" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-63305">
                                            <a href="#">Data Insights</a>
                                            </li>
                                            <li id="menu-item-47822" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-47822">
                                            <a href="#">Help Center</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-3 col-lg-2">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_company_navigation">
                                    <div class="nav-header">
                                        Company
                                    </div>
                                    <div class="menu-footer-company-navigation-container">
                                        <ul id="menu-footer-company-navigation" class="nav d-flex flex-column" >
                                            <li id="menu-item-47827" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-47825 current_page_item menu-item-47827">
                                            <a href="#">About</a>
                                            </li>
                                            <li id="menu-item-60548" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-60548">
                                            <a href="#">Press</a>
                                            </li>
                                            <li id="menu-item-47828" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-47828">
                                            <a href="#">Careers</a>
                                            </li>
                                            <li id="menu-item-338" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-338">
                                            <a href="#">Contact</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-bottom">
            <div class="container">
                <div class="row flex-row">
                    <div class="col col-12 col-lg-4 text-center text-md-left d-flex align-items-center justify-content-center justify-content-md-start">
                        <div class="nav nav-legal py-1 py-lg-0">
                            <div class="footer-nav-wrapper" id="footer_legal_navigation">
                                <div class="menu-footer-legal-navigation-container">
                                    <ul id="menu-footer-legal-navigation" class="nav d-flex flex-row" >
                                       
                                        <li id="menu-item-47844" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-47844">
                                        <a href="#">Terms &amp; Conditions</a>
                                        </li>
                                        <li id="menu-item-47845" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-47845">
                                        <a href="#">Privacy</a>
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col col-12 col-md-6 col-lg-4 text-left text-md-right align-items-center justify-content-center d-flex justify-content-md-start justify-content-lg-center">
                        <div class="legal text-center text-md-left text-lg-center py-1 py-lg-0">
                            © 2021 competes. All Rights Reserved.
                        </div>
                    </div>

                    <div class="col col-12 col-md-6 col-lg-4 text-center text-md-right">
                        <div class="nav nav-social text-md-right py-1 py-lg-0">
                            <a class="social-icon-link" href="#" target="_blank" title="Twitter">
                                <svg class="svg-inline--fa fa-twitter fa-w-16" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                    <path
                                        fill="currentColor"
                                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                    ></path>
                                </svg>
                               
                                <span class="sr-only">Twitter (External link)</span>
                            </a>
                            <a class="social-icon-link" href="#" target="_blank" title="LinkedIn">
                                <svg
                                    class="svg-inline--fa fa-linkedin fa-w-14"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="linkedin"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    data-fa-i2svg=""
                                >
                                    <path
                                        fill="currentColor"
                                        d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                                    ></path>
                                </svg>
                               
                                <span class="sr-only">LinkedIn (External link)</span>
                            </a>
                            <a class="social-icon-link" href="#" target="_blank" title="FaceBook">
                                <svg
                                    class="svg-inline--fa fa-facebook fa-w-16"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="facebook"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    data-fa-i2svg=""
                                >
                                    <path
                                        fill="currentColor"
                                        d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                                    ></path>
                                </svg>
                              
                                <span class="sr-only">FaceBook (External link)</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="row -center hero-row popupde">
    <div class="close2">
        <a class="close-atag" href="javascript:void(0);">
            <img src="/assets/images/xlose.png" />
        </a>
    </div>
<img class="feature-callout__img" src="https://luna1.co/005100.png" alt="Image of team" width="112" />
            <div class="signupForm-homepage-center col-xsmall-12 col-small-8 col-small-offset-2 col-medium-10 col-medium-offset-1 col-large-8 col-large-offset-2 col-xlarge-6 col-xlarge-offset-3">
                <h4 class="heading -spacing-2 -h3">Sign up</h4>
                <p class="subheading -spacing-1 hero-legal-copy">
                    By signing up, I agree to the Asana <a href="/terms#privacy-policy" class="css-1kfjk3x-Link">Privacy Policy</a> and <a href="/terms#terms-of-service" class="css-1kfjk3x-Link">Terms of Service</a>.
                </p>
                <form action="get-started/verify" method="get" novalidate="" class="signupForm hidden-logged-in signupForm-homepage-center" __bizdiag="1239199729" __biza="WJ__">
                    <div class="signupForm-container">
                        <div class="signupForm-row">
                        <div class="form-group">
                                
                                <input type="email" name="e" required  class="form-control" placeholder="name@company.com" id="work-email" />
                            </div>

                            <div class="submit-btn">
                                <button id="go-to-nextStep"  onClick={this.toggleBox} class="btn btn-lg btn-flat btn-block btn-primary">Next</button>
                            </div>
                        </div>
                    </div>
                </form>
                
            </div>
        </div>
    

  </div>
    );
}
}

export default Solutions;