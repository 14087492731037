import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';



class Privacy extends React.Component {

    componentWillMount () {
        
        const script = document.createElement("script");
     
        script.src = "/assets/js/menutoggle.js";
       
        script.async = true;
     
        document.body.appendChild(script);
       
      }
      
      render(){
 
        return(
            <div class="privacypageupperdiv  page-create-account ">
            <div class="main-banner companybg newonetop pri thissignup">
        
                <div class="container">
                
                <header class="main-header d-flex justify-content-between">
               <div class="site-logo">
                  
                  <NavLink to="/">competes</NavLink>
               </div>
               
            </header>
                
            </div> </div> 
    
            <section class="sectionBuilder hero --feature-callout -first">
    <div class="container -full">
        <div class="row -center hero-row">
            <div class="signupForm-homepage-center col-xsmall-12 col-small-8 col-small-offset-2 col-medium-10 col-medium-offset-1 col-large-8 col-large-offset-2 col-xlarge-6 col-xlarge-offset-3">
                <h4 class="heading -spacing-2 -h3">Sign up</h4>
                <p class="subheading -spacing-1 hero-legal-copy">
                    By signing up, I agree to the Upsignal <a href="/terms#privacy-policy" class="css-1kfjk3x-Link">Privacy Policy</a> and <a href="/terms#terms-of-service" class="css-1kfjk3x-Link">Terms of Service</a>.
                </p>
                <form action="verify" method="get" novalidate="" class="signupForm hidden-logged-in signupForm-homepage-center" __bizdiag="1239199729" __biza="WJ__">
                    <div class="signupForm-container">
                        <div class="signupForm-row">
                        <div class="form-group">
                                
                                <input type="email" name="e" required  class="form-control" placeholder="name@email.com" id="work-email" />
                            </div>

                            <div class="submit-btn">
                                <button id="go-to-nextStep"  onClick={this.toggleBox} class="btn btn-lg btn-flat btn-block btn-primary">Next</button>
                            </div>
                        </div>
                    </div>
                </form>
                
            </div>
        </div>
        <div class="row -ta-center page-aside-row">
            <div>
                <div class="feature-callout">
                    <img class="feature-callout__img" src="https://luna1.co/005100.png" alt="Image of team" width="112" />
                    <h2
                        class="feature-callout__header"
                        data-gtm-vis-recent-on-screen-6076898_224="1739"
                        data-gtm-vis-first-on-screen-6076898_224="1739"
                        data-gtm-vis-total-visible-time-6076898_224="100"
                        data-gtm-vis-has-fired-6076898_224="1"
                    >
                        Your plan includes
                    </h2>
                    <div class="feature-callout__list list">
                        <div class="list__icon"><img src="https://luna1.co/dec30d.png" alt="check icon" width="18" /></div>
                        <p class="list__item">Unlimited tasks and projects</p>
                        <div class="list__icon"><img src="https://luna1.co/dec30d.png" alt="check icon" width="18" /></div>
                        <p class="list__item">Unlimited storage</p>
                        <div class="list__icon"><img src="https://luna1.co/dec30d.png" alt="check icon" width="18" /></div>
                        <p class="list__item">List, Board, and Calendar views</p>
                        <div class="list__icon"><img width="18" /></div>
                        <p class="list__item">Plus much more...</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

       
       </div>
        );
    }
}

export default Privacy;