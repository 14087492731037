import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';
import Trends from './partials/Trends';


class Gmx extends React.Component {
   componentWillMount () {
      const script = document.createElement("script");
   
      script.src = "/assets/js/slick.min.js";
     // script.src = "/assets/js/main.js";
      script.async = true;
   
      document.body.appendChild(script);

      const script1 = document.createElement("script");
   
     // script1.src = "/assets/js/slick.min.js";
      script1.src = "/assets/js/main.js";
      script1.async = true;
   
      document.body.appendChild(script1);
    }
   render(){
 
    return(
        <div class="interblockpage">

<header class="main-header d-flex justify-content-between">
<div class="container">
               <div class="site-logo">
                  <a href="javascript:void(0)">

                      <img src="assets/images/ibc_logo.png" class="interblockchainlogo" />


                  </a>
               </div>
               </div>
            </header>

            <div class="inter topinter">
            <div class="container">
               <h3>ECOSYSTEM</h3>
               <ul>
                  <li>
                     <img src="assets/images/snx_icon.png" />
                  </li>
                  <li>
                     <img src="assets/images/snx_icon.png" />
                  </li>
                  <li>
                     <img src="assets/images/snx_icon.png" />
                  </li>
                  <li>
                     <img src="assets/images/snx_icon.png" />
                  </li>
                  <li>
                     <img src="assets/images/snx_icon.png" />
                  </li>
               </ul>

               </div>  </div>

               <div class="inter seciner">
            <div class="container">
               <h3>Portfolio</h3>
               <ul>
                  <li>
                     <img src="assets/images/snx_icon.png" />
                  </li>
                  <li>
                     <img src="assets/images/snx_icon.png" />
                  </li>
                  <li>
                     <img src="assets/images/snx_icon.png" />
                  </li>
                  <li>
                     <img src="assets/images/snx_icon.png" />
                  </li>
                  <li>
                     <img src="assets/images/snx_icon.png" />
                  </li>
               </ul>

               </div>  </div>

               <div class="fotter-upper">

               <div class="text_container">
            <div class="container">
               <div class="iopo">
                <div class="row">
                  
                  <div class="col-md-12 text_container_right">
                    <p class="mb-8">“We really value our partnership with Scale and their ability to deliver custom workflows that suit our needs across a variety of mapping and deep learning models. Scale's high-quality, rapidly labeled data will enable us to ramp our map expansion, allowing us to provide more service to customers like Dominos.”</p>
                    <h4>David Garber</h4>
                    <p>Product Manager, TRI</p>
                  </div>
                </div>
                <div class="border_bottom"></div>
                 </div>
                
            </div>
        </div>


               </div>

          </div>
    );
}
}

export default Gmx;
