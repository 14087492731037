import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';
import Trends from './partials/Trends';


class Gmx extends React.Component {
   componentWillMount () {
      const script = document.createElement("script");
   
      script.src = "/assets/js/slick.min.js";
     // script.src = "/assets/js/main.js";
      script.async = true;
   
      document.body.appendChild(script);

      const script1 = document.createElement("script");
   
     // script1.src = "/assets/js/slick.min.js";
      script1.src = "/assets/js/main.js";
      script1.async = true;
   
      document.body.appendChild(script1);

      var fileref = document.createElement("link");
fileref.rel = "stylesheet";
fileref.type = "text/css";
fileref.href = "/assets/styles/stylesw.css";
document.getElementsByTagName("head")[0].appendChild(fileref);
    }
   render(){
 
    return(
        <div class="interest">

<div class="debank_container">
            <div class="container">
                <div class="row debank_header">
                    <div class="col-md-6 debank_header_left"><div class="site-logo">
                  <a href="javascript:void(0)">
                      <img src="/assets/images/P-Icon_black.png" class="desklogo" />
                  </a>
               </div></div>
                    <div class="col-md-6 debank_header_right"><a class="f1q" href="#">Deposits</a>  <a class="f2q" href="#">Account Name</a></div>
                </div>

                <div class="row debank_tabs">
                    <div class="col-md-6 debank_tabs_left">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                          <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="portfolio-tab" data-bs-toggle="tab" data-bs-target="#portfolio" type="button" role="tab" aria-controls="portfolio" aria-selected="true">Portfolio</button>
                          </li>
                        </ul>
                    </div>
                    <div class="col-md-6 debank_tabs_right">
                        <span class="date_text">Data updated<span class="date_time">21 hours </span>ago</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="debank_tab_content">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="tab-content" id="myTabContent">
                         
                          <div class="tab-pane fade show active" id="portfolio" role="tabpanel" aria-labelledby="portfolio-tab">
                            <div class="debank_tab_area">
                                <ul>
                                    <li>
                                        <div><div><span>$22,403.82</span></div></div>
                                    </li>
                                    
                                </ul>
                                <div class="portfolio_month">November</div>
                                <div class="portfolio_cells">
                                    <div class="portfolio_cell">
                                        <div class="cell_item">
                                            <div class="cell_img">
                                                <div><img src="/assets/images/usd-coin-usdc-logo.png" /></div>
                                            </div>
                                            <div class="cell_itemname">
                                                <div class="cell_itemname_title">Interest</div>
                                                <div class="cell_itemname_price">$47.39</div>
                                            </div>
                                            <div class="cell_itemnum">1</div>
                                        </div>
                                    </div>
                                    <div class="portfolio_cell">
                                        <div class="cell_item">
                                            <div class="cell_img">
                                                <div><img src="/assets/images/usd-coin-usdc-logo.png" /></div>
                                            </div>
                                            <div class="cell_itemname">
                                                <div class="cell_itemname_title">Interest</div>
                                                <div class="cell_itemname_price">$47.39</div>
                                            </div>
                                            <div class="cell_itemnum">2</div>
                                        </div>
                                    </div>
                                    <div class="portfolio_cell">
                                        <div class="cell_item">
                                            <div class="cell_img">
                                                <div><img src="/assets/images/usd-coin-usdc-logo.png" /></div>
                                            </div>
                                            <div class="cell_itemname">
                                                <div class="cell_itemname_title">Interest</div>
                                                <div class="cell_itemname_price">$47.39</div>
                                            </div>
                                            <div class="cell_itemnum">3</div>
                                        </div>
                                    </div>
                                    <div class="portfolio_cell">
                                        <div class="cell_item">
                                            <div class="cell_img">
                                                <div><img src="/assets/images/usd-coin-usdc-logo.png" /></div>
                                            </div>
                                            <div class="cell_itemname">
                                                <div class="cell_itemname_title">Interest</div>
                                                <div class="cell_itemname_price">$47.39</div>
                                            </div>
                                            <div class="cell_itemnum">4</div>
                                        </div>
                                    </div>
                                    <div class="portfolio_cell">
                                        <div class="cell_item">
                                            <div class="cell_img">
                                                <div><img src="/assets/images/usd-coin-usdc-logo.png" /></div>
                                            </div>
                                            <div class="cell_itemname">
                                                <div class="cell_itemname_title">Interest</div>
                                                <div class="cell_itemname_price">$47.39</div>
                                            </div>
                                            <div class="cell_itemnum">5</div>
                                        </div>
                                    </div>
                                    <div class="portfolio_cell">
                                        <div class="cell_item">
                                            <div class="cell_img">
                                                <div><img src="/assets/images/usd-coin-usdc-logo.png" /></div>
                                            </div>
                                            <div class="cell_itemname">
                                                <div class="cell_itemname_title">Interest</div>
                                                <div class="cell_itemname_price">$47.39</div>
                                            </div>
                                            <div class="cell_itemnum">6</div>
                                        </div>
                                    </div>
                                    <div class="portfolio_cell">
                                        <div class="cell_item">
                                            <div class="cell_img">
                                                <div><img src="/assets/images/usd-coin-usdc-logo.png" /></div>
                                            </div>
                                            <div class="cell_itemname">
                                                <div class="cell_itemname_title">Interest</div>
                                                <div class="cell_itemname_price">$47.39</div>
                                            </div>
                                            <div class="cell_itemnum">7</div>
                                        </div>
                                    </div>
                                    <div class="portfolio_cell">
                                        <div class="cell_item">
                                            <div class="cell_img">
                                                <div><img src="/assets/images/usd-coin-usdc-logo.png" /></div>
                                            </div>
                                            <div class="cell_itemname">
                                                <div class="cell_itemname_title">Interest</div>
                                                <div class="cell_itemname_price">$47.39</div>
                                            </div>
                                            <div class="cell_itemnum">8</div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                          </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
          </div>
    );
}
}

export default Gmx;
