import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';
//import Trends from './partials/Trends';


class Company extends React.Component {

    componentWillMount () {
        
        const script = document.createElement("script");
     
        script.src = "/assets/js/menutoggle.js";
       
        script.async = true;
     
        document.body.appendChild(script);
       
      }
      
   render(){
 
    return(
        <div>
        <div class="main-banner companybg newonetop">
        <div class="container bo">
                <img src="/assets/images/top-banner-below-3-3px.svg" class="middlediv container" />
                </div>
                <div class="container">
                <img src="/assets/images/top-banner-below-3-3px.svg" class="middlediv container" />
                <header class="main-header d-flex justify-content-between">
               <div class="site-logo">
                  
                  <NavLink to="/">competes</NavLink>
               </div>
               <div class="menu-toggle-icon">
                  <span class="hamburger-inner"></span>
               </div>
               <div class="site-login">
                  <div class="menu-toggle-bg"></div>
                  <div class="site-login-inner">
                     <div class="site-logo menu-with-logo">
                     <NavLink to="/">competes</NavLink>
                     </div>
                      
                   
                     <NavLink to="/signals" className="mr-4 top-link mobi" >Signals</NavLink>
                     
                     <NavLink to="/solutions" className="mr-4 top-link">Solutions</NavLink>
                     <NavLink to="/signals" className="mr-4 top-link desk">Signals</NavLink>
                    
                     <NavLink to="/gmx/about" className="mr-4 top-link">About</NavLink> 
                     
                     
                  </div>
               </div>
            </header>
                
            <div class="row justify-content-center">
               <div class="col-md-9">
               <div class="banner-content text-center comp">
                     <h1 class="banner-title">About Competes</h1>
                     <div class="banner-description">
                        <p><span class="freet" >Competes provides real-time insights about your audience</span></p>
                     </div>
                    
                     </div>
               <div>
                   <p class="ptext">
                   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
                   labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                   laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in 
                   voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat 
                   non proident, 
                   sunt in culpa qui officia deserunt mollit anim id est laborum
                   </p>
               </div>
               
               </div> </div>  </div> </div> 

               <div class="call-to-action container combg">
                <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-7">
                        <div class="pl-5">
                            <h2 class="freeh2">Free Weekly Prospects</h2>
                            <p class="pclass">Get a weekly email telling you which of your target accounts are researching topics relevant to your business.</p>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="buttonstart">
                        <NavLink to="/signup" className="btn btn-lg btn-flat btn-dark">Start Now
                        <svg focusable="false" class="ibm-duo-u--link-icon--arrow-right" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" slot="icon"><path d="M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z"></path></svg>
                        </NavLink> </div>
                    </div>
                </div>
                </div>
                </div>
                <section class="g-grid-container g-open-positions">
    <header class="header">
        <h3 class="heading g-type-display-2">Open Positions by Department</h3>
        <p class="subheading g-type-body-large">Check out the links below to learn more about each department at HashiCorp, hear directly from our employees and view open jobs.</p>
    </header>
    
</section>

               <section id="top" data-section-id="mnutwt" data-section="main" class="upflow-12 pt-2 pb-6 custom text-center bg-center headlines-dark">
    <div class="container" data-section-container-main="mnutwt">
        <div data-section-row-main="mnutwt" class="row flex-row justify-content-center align-items-center">
          
            <div class="col col-cards col-12 py-2 py-md-4">
                <div class="col-inside col-inside-cards text-center list-">
                    <div class="row flex-row row-cards justify-content-center justify-content-md-around">
                        <div class="col col-card col-12 col-sm-10 col-md-6 col-lg-4">
                            <div class="col-inside col-inside-card h-100 py-2">
                                <a class="wrapper-link card-wrapper-link" href="javascript:void(0)" >
                                    <div class="card h-100 d-flex flex-column justify-content-md-between dropshadow no-border top-border-6px text-left hl-sm card-icon-image-left border-dark">
                                        <div class="wrapper-card-content d-flex justify-content-md-between h-100 flex-row">
                                            <figure class="figure figure-cards w-100">
                                                <img
                                                    class="lazy image img-fluid figure-img icon-image rounded-circle card-img-top loaded"
                                                    alt="1"
                                                    src="assets/images/fitness_insights.jpg"
                                                />
                                            </figure>

                                            <div class="card-body d-flex flex-column h-100 justify-content-between">
                                                <div class="d-flex flex-column h-100 justify-content-md-start">
                                                    <h5 class="headline headline-col headline-col-cards">
                                                        <span class="superheadline">Live Event</span>

                                                        Virtual NOVA: Advertising in 2021 and Beyond
                                                    </h5>

                                                    <div class="">
                                                        <p>Join us and industry leaders to discuss the state of the advertising industry now and in the future.</p>
                                                    </div>
                                                </div>

                                                <div class="buttons buttons-cards">
                                                    <div class="link link-primary icon-after wrapped-link">
                                                        Register Now
                                                        <svg
                                                            class="svg-inline--fa fa-arrow-right fa-w-14"
                                                            aria-hidden="true"
                                                            focusable="false"
                                                            data-prefix="fal"
                                                            data-icon="arrow-right"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 448 512"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z"
                                                            ></path>
                                                        </svg>
                                                      
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col col-card col-12 col-sm-10 col-md-6 col-lg-4">
                            <div class="col-inside col-inside-card h-100 py-2">
                                <a class="wrapper-link card-wrapper-link" href="javascript:void(0)" >
                                    <div class="card h-100 d-flex flex-column justify-content-md-between dropshadow no-border top-border-6px text-left hl-sm card-icon-image-left border-dark">
                                        <div class="wrapper-card-content d-flex justify-content-md-between h-100 flex-row">
                                            <figure class="figure figure-cards w-100">
                                                <img
                                                    class="lazy image img-fluid figure-img icon-image rounded-circle card-img-top loaded"
                                                    alt="1"
                                                    src="assets/images/fitness_insights.jpg"
                                                />
                                            </figure>

                                            <div class="card-body d-flex flex-column h-100 justify-content-between">
                                                <div class="d-flex flex-column h-100 justify-content-md-start">
                                                    <h5 class="headline headline-col headline-col-cards">
                                                        <span class="superheadline">Live Event</span>

                                                        Virtual NOVA: Advertising in 2021 and Beyond
                                                    </h5>

                                                    <div class="">
                                                        <p>Join us and industry leaders to discuss the state of the advertising industry now and in the future.</p>
                                                    </div>
                                                </div>

                                                <div class="buttons buttons-cards">
                                                    <div class="link link-primary icon-after wrapped-link">
                                                        Register Now
                                                        <svg
                                                            class="svg-inline--fa fa-arrow-right fa-w-14"
                                                            aria-hidden="true"
                                                            focusable="false"
                                                            data-prefix="fal"
                                                            data-icon="arrow-right"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 448 512"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z"
                                                            ></path>
                                                        </svg>
                                                      
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col col-card col-12 col-sm-10 col-md-6 col-lg-4">
                            <div class="col-inside col-inside-card h-100 py-2">
                                <a class="wrapper-link card-wrapper-link" href="javascript:void(0)" data-g-action="" data-g-label="">
                                    <div class="card h-100 d-flex flex-column justify-content-md-between dropshadow no-border top-border-6px text-left hl-sm card-icon-image-left border-dark">
                                        <div class="wrapper-card-content d-flex justify-content-md-between h-100 flex-row">
                                            <figure class="figure figure-cards w-100">
                                                <img
                                                    class="lazy image img-fluid figure-img icon-image rounded-circle card-img-top loaded"
                                                    alt="1"
                                                    src="assets/images/fitness_insights.jpg"
                                                />
                                            </figure>

                                            <div class="card-body d-flex flex-column h-100 justify-content-between">
                                                <div class="d-flex flex-column h-100 justify-content-md-start">
                                                    <h5 class="headline headline-col headline-col-cards">
                                                        <span class="superheadline">Live Event</span>

                                                        Virtual NOVA: Advertising in 2021 and Beyond
                                                    </h5>

                                                    <div class="">
                                                        <p>Join us and industry leaders to discuss the state of the advertising industry now and in the future.</p>
                                                    </div>
                                                </div>

                                                <div class="buttons buttons-cards">
                                                    <div class="link link-primary icon-after wrapped-link">
                                                        Register Now
                                                        <svg
                                                            class="svg-inline--fa fa-arrow-right fa-w-14"
                                                            aria-hidden="true"
                                                            focusable="false"
                                                            data-prefix="fal"
                                                            data-icon="arrow-right"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 448 512"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z"
                                                            ></path>
                                                        </svg>
                                                      
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                      
                      </div>
                </div>
            </div>
           
        </div>
    </div>
</section>

<section id=""  class="py-0 hl-sm bg-white-to-lighter text-left bg-cover bg-top bottomseccomp pk">
    <div class="container" data-section-container-main="zqdmez">
        <div data-section-row-main="zqdmez" class="row flex-row justify-content-center justify-content-md-around align-items-center">
            <div class="col col-image col-image col-12 col-md-5 py-2 py-md-4">
                <div class="col-inside col-inside-image col-inside-image w-100">
                    <figure class="figure figure-image w-100">
                        <img
                            class="lazy image img-fluid figure-img loaded"
                            alt="1"
                            src="assets/images/know-grow-audience-min-1024x1024-1-500x500.png"
                            data-src="assets/images/know-grow-audience-min-1024x1024-1-500x500.png"
                            data-first-load="assets/images/know-grow-audience-min-1024x1024-1-500x500.png"
                            data-srcset="assets/images/know-grow-audience-min-1024x1024-1-500x500.png 500w, assets/images/know-grow-audience-min-1024x1024-1-300x300.png 300w, assets/images/know-grow-audience-min-1024x1024-1.png 1024w, assets/images/know-grow-audience-min-1024x1024-1-150x150.png 150w, assets/images/know-grow-audience-min-1024x1024-1-768x768.png 768w, assets/images/know-grow-audience-min-1024x1024-1-800x800.png 800w"
                            sizes="(max-width: 500px) 100vw, 500px"
                            srcset="
                                assets/images/know-grow-audience-min-1024x1024-1-500x500.png  500w,
                                assets/images/know-grow-audience-min-1024x1024-1-300x300.png  300w,
                                assets/images/know-grow-audience-min-1024x1024-1.png         1024w,
                                assets/images/know-grow-audience-min-1024x1024-1-150x150.png  150w,
                                assets/images/know-grow-audience-min-1024x1024-1-768x768.png  768w,
                                assets/images/know-grow-audience-min-1024x1024-1-800x800.png  800w
                            "
                            data-was-processed="true"
                        />
                    </figure>
                </div>
            </div>

            <div class="col col-copy col-12 col-md-6 col-lg-5 py-2 py-md-4">
                <div class="col-inside col-inside-copy w-100">
                    <h2 class="headline headline-col headline-col-copy">
                        Know and grow your&nbsp;audience
                    </h2>

                    <div class="d-flex flex-column flex-column-wrap">
                        <div class="text text-col text-col-copy order-first">
                            <p class="text-lg">The Competes Platform empowers brands, agencies, and publishers to know and grow their audiences on the open&nbsp;internet.</p>
                        </div>

                        <div class="buttons buttons-copy">
                           
                             <NavLink to="/" className="btn btn-outline-primary icon icon-after button-custom">Learn More
                             <svg focusable="false" class="ibm-duo-u--link-icon--arrow-right" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" slot="icon"><path d="M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z"></path></svg>
                             </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

                
                <div class="g-call-to-action variant-compact theme-light">
    <div class="g-grid-container">
        <h2 data-testid="heading" class="g-type-display-2">Ready to get started?</h2>
        <div class="content-and-links">
            <p data-testid="content" class="g-type-body-large">Request a demo or talk to our technical sales team to answer your questions.</p>
            <div data-testid="links" class="links">
                <a class="g-btn size-medium variant-primary background-light" data-ga-button="contact-sales" href="#"><span class="text g-type-buttons-and-standalone-links">Contact Sales</span></a>
                <a class="g-btn size-medium variant-secondary background-light" data-ga-button="request-demo" href="#"><span class="text g-type-buttons-and-standalone-links">Request Demo</span></a>
            </div>
        </div>
    </div>
</div>
                <footer class="footer footer-site wrap-site-footer bg-darker">
    <div class="wrap-site-footer-inside">
        <div class="footer-top">
            <div class="container">
                <div class="row flex-row row-footer-nav justify-content-start justify-content-md-between">
                    <div class="col col-12 col-lg-4 col-xl-3 my-1 order-last order-lg-first">
                        <div class="organization py-2 py-md-1 py-lg-0" >
                            <meta itemprop="name" content="Competes" />
                            <figure class="logo">
                               
                                <a aria-current="page" class="active" href="/">Competes</a>
                               
                            </figure>
                            <div>
                                
                                <div id="choice-footer-msg" class="choice-footer-msg ccpa-msg-added">
                                    We use cookies and other data collection technologies to provide the best experience for our customers. You may request that your data not be shared with third parties here:
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col col-12 col-lg-8 my-1 order-first order-lg-last">
                        <div class="row">
                           

                            <div class="col col-6 col-sm-3 mb-2">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_solutions_navigation">
                                    <div class="nav-header">
                                        Solutions
                                    </div>
                                    <div class="menu-footer-solutions-container">
                                        <ul id="menu-footer-solutions" class="nav d-flex flex-column" >
                                            <li id="menu-item-49409" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49409">
                                            <a href="#">
                                                    Publishers
                                                </a>
                                            </li>
                                            <li id="menu-item-49410" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49410">
                                            <a href="#">
                                                    Advertisers
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-3 mb-2">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_resources_navigation">
                                    <div class="nav-header">
                                        Resources
                                    </div>
                                    <div class="menu-footer-resources-navigation-container">
                                        <ul id="menu-footer-resources-navigation" class="nav d-flex flex-column" >
                                            <li id="menu-item-337" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-337">
                                            <a href="#">Blog</a>
                                            </li>
                                            <li id="menu-item-336" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-336">
                                            <a href="#">Webinars</a>
                                            </li>
                                            <li id="menu-item-47821" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-47821">
                                            <a href="#">Case Studies</a>
                                            </li>
                                            <li id="menu-item-63305" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-63305">
                                            <a href="#">Data Insights</a>
                                            </li>
                                            <li id="menu-item-47822" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-47822">
                                            <a href="#">Help Center</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-3 col-lg-4">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_company_navigation">
                                    <div class="nav-header">
                                        Company
                                    </div>
                                    <div class="menu-footer-company-navigation-container">
                                        <ul id="menu-footer-company-navigation" class="nav d-flex flex-column" >
                                            <li id="menu-item-47827" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-47825 current_page_item menu-item-47827">
                                            <a href="#">About</a>
                                            </li>
                                            <li id="menu-item-60548" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-60548">
                                            <a href="#">Press</a>
                                            </li>
                                            <li id="menu-item-47828" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-47828">
                                            <a href="#">Careers</a>
                                            </li>
                                            <li id="menu-item-338" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-338">
                                            <a href="#">Contact</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            
                            <div class="col col-6 col-sm-3 col-lg-2">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_company_navigation">
                                    
                                    <div class="menu-footer-company-navigation-container">
                                        <ul id="menu-footer-company-navigation" class="nav d-flex flex-column" >
                                            <li id="menu-item-47827" class="soc menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-47825 current_page_item menu-item-47827">
                                            <svg class="svg-inline--fa fa-twitter fa-w-16" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                    <path
                                        fill="currentColor"
                                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                    ></path>
                                </svg> Twitter
                                            </li>
                                            <li id="menu-item-60548" class="soc menu-item menu-item-type-custom menu-item-object-custom menu-item-60548">
                                            <img src="/assets/images/reddit.svg"  class="Sitemap__StyledIcon-uyke27-8 drcgmZ" /> Reddit
                                            </li>
                                            <li id="menu-item-47828" class="soc menu-item menu-item-type-post_type menu-item-object-page menu-item-47828">
                                            <svg
                                    class="svg-inline--fa fa-facebook fa-w-16"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="facebook"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    data-fa-i2svg=""
                                >
                                    <path
                                        fill="currentColor"
                                        d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                                    ></path>
                                </svg> FaceBook
                                            </li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</footer>
<div class="row -center hero-row popupde">
    <div class="close2">
        <a class="close-atag" href="javascript:void(0);">
            <img src="assets/images/xlose.png" />
        </a>
    </div>
<img class="feature-callout__img" src="https://luna1.co/005100.png" alt="Image of team" width="112" />
            <div class="signupForm-homepage-center col-xsmall-12 col-small-8 col-small-offset-2 col-medium-10 col-medium-offset-1 col-large-8 col-large-offset-2 col-xlarge-6 col-xlarge-offset-3">
                <h4 class="heading -spacing-2 -h3">Sign up</h4>
                <p class="subheading -spacing-1 hero-legal-copy">
                    By signing up, I agree to the Asana <a href="/terms#privacy-policy" class="css-1kfjk3x-Link">Privacy Policy</a> and <a href="/terms#terms-of-service" class="css-1kfjk3x-Link">Terms of Service</a>.
                </p>
                <form action="get-started/verify" method="get" novalidate="" class="signupForm hidden-logged-in signupForm-homepage-center" __bizdiag="1239199729" __biza="WJ__">
                    <div class="signupForm-container">
                        <div class="signupForm-row">
                        <div class="form-group">
                                
                                <input type="email" name="e" required  class="form-control" placeholder="name@company.com" id="work-email" />
                            </div>

                            <div class="submit-btn">
                                <button id="go-to-nextStep"  onClick={this.toggleBox} class="btn btn-lg btn-flat btn-block btn-primary">Next</button>
                            </div>
                        </div>
                    </div>
                </form>
                
            </div>
        </div>
 </div>
    );
}
}

export default Company;